import React, { useState, useEffect, useContext } from 'react'
import UseWebService from '../UseWebService'
import MiContexto from '../MiContexto'


import Loader from 'react-loader-spinner'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { HashRouter, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

export default function NoDevice(props) {

    const { t } = useTranslation();

    const { user, isBrowserSpanish } = useContext(MiContexto)

    const [pendingProduct, setPendingProduct] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        loadPendingProduct()


    }, [])

    //NO HAY DISPOSITIVOS EN LA CUENTA###############
    let installGuideLink = `https://catwatchful.com/#use`
    let downloadLink = `https://catwatchful.pink/apk/getInstaller.php`



    const loadPendingProduct = async () => {
        setIsLoading(true)
        await UseWebService("operation=getPendingProduct&email=" + user.email).then(resp => {
            console.log(resp.data)
            if (resp.statusCode == 200) setPendingProduct(resp.data[0])
        })
        setIsLoading(false)
    }


    return (
        <div className='container'>

            <div className="text-center">
                <a href="javascript:void(0)"> <img src="./images/logo.svg" className="img-responsive" width="80px" alt="CatWatchful" /></a>
            </div>

            <div className='d-flex justify-content-center mt-4'>
                <HashRouter><Link to="/activeuser"> <span className='text-light'>{user.email}</span> </Link></HashRouter>

            </div>
            <div className='d-flex justify-content-center small text-danger' onClick={props.closeSession}><u> {t("Logout.logout")}</u></div>

            <hr className='bg-secondary' />

            <div className='d-flex justify-content-center small' >
                <Fade >
                    <div className="alert alert-warning my-3 py-1 text-center" style={{ width: "400px" }} >
                        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                        <i className="fa fa-warning fa-2x text-danger mx-2 mt-2" /><strong>{t('NoDevice.title')}</strong>

                        <div className='mr-1 mt-2 text-left'> {t('NoDevice.mustInstall')}</div>
                        
                        <a href={downloadLink} target="_blank"><button className="btn btn-primary my-3" ><i className="fa fa-download mr-2" />{t('NoDevice.download')}</button></a>

                        <div>
                            <a href={installGuideLink} target="_blank"><i className="fa fa-hand-o-right mr-1" />{t('NoDevice.read')}</a>
                        </div>
                    </div>
                </Fade >

            </div>



            {isLoading && <div className='text-center'><Loader type="Rings" color="#fff" > </Loader>{t('NoDevice.loading')} </div>}


            {pendingProduct && !isLoading &&
                //PENDING PRODUCT 
                <div className='d-flex justify-content-center mt-4'>

                    <Zoom>
                        <div className='bg-dark rounded' style={{ width: "400px", borderColor: "#" + pendingProduct.color, borderStyle: "solid", borderWidth: "1px" }}>

                            <div className='text-center my-2 h4' style={{ borderBottom: "#" + pendingProduct.color, borderBottomStyle: "solid", borderBottomWidth: "1px", color: "#" + pendingProduct.color }}>
                                <i className='fa fa-certificate mr-1' />
                                {t('NoDevice.currentSub')}
                            </div>

                            <div className="d-flex pl-4">
                                {/* <img className="pt-4" style={{ width: "85px", height: '140px', borderColor: "#" + pendingProduct.color }} src={"data:image/png;base64, " + pendingProduct.logo} alt="Card image cap" /> */}

                                <div className="card-body">
                                    <h2 className=" d-inline-block" style={{ color: "#" + pendingProduct.color }}><i className="fa fa-angle-right fa-1x mr-1" />{pendingProduct.name}</h2>
                                    <div className=" d-inline-block ml-2 font-italic text-muted h4">U$S {pendingProduct.price} </div>

                                    <div className="card-text small mb-2" style={{ color: "#" + pendingProduct.color }}> {pendingProduct.desc} </div>

                                    <div className="card-text"><i className='fa fa-clock-o mr-1' />{t('NoDevice.duration')}: {pendingProduct.daysDuration} {t('NoDevice.days')}</div>
                                    <div className="card-text"><i className='fa fa-mobile mr-1 ml-1' />{t('NoDevice.devices')}: {pendingProduct.cantDevices}</div>
                                </div>
                            </div>

                            <div className='px-2 pb-2 small text-left text-secondary'>
                                <hr className='bg-secondary py-0' />
                                {t('NoDevice.explain')}
                            </div>



                        </div>
                    </Zoom>
                </div>
            }

        </div>
    )



}