import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import { functionGetRandomRealTimeData, sendPushMessageToDevice } from '../CloudFunctions'
import miFireBase from '../config/config'


import { ShowFiltersResult, AlertMessage, ShowNoItemsYet, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { removeFromMatriz, addToMatrizOfArrayItemsByDate, resizeNavBar, openUrl, checkFileExistsFromFireStorage, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl,deleteFileFromFireStorage } from '../CommonFunctions'
import { ImageGallery2, ImageDialog } from './ImageGallery2'

//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


//reveal-effect
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';


//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";


// import ImageGallery from 'react-image-gallery';
// import "react-image-gallery/styles/css/image-gallery.css";
// import { Pixelify } from "react-pixelify";
import { image2 } from "../imagesBase64Demo.js"


//Traduccion
import { withTranslation, useTranslation } from 'react-i18next';

const FEATURE_ID = 6
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 30

const MAX_TIMEOUT_MS = 10000




class ScreenCapture extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            pictures: null,
            picturesFiltered: [],
            picturesOrderRecentFirst: true,

            selectedPicture: "null",

            filterDate: null,

            //----real time
            screenCaptureStatus: 0,
            currentPictureBase64: null,
            autoScreenCapture: false,
            //----
            fbTrash: [],//elementos que debo eliminar de firebase al salir (solo en realtime)


            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            alertMessage: { message: "", title: "", isError: false },

            renderSize: 50
        }


    }


    getOriginalDbRef = () => {
        //CREO el puntero

        if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
            return miFireBase.database().ref(this.context.dbPathDummy).child("MDscreenCaptures")

        return miFireBase.database().ref(this.context.dbPath).child("MDscreenCaptures")
    }

    getRealTimeDbRef = () => {
        //CREO el puntero realtime
        return miFireBase.database().ref(this.context.dbPath).child("realTimeResponse")
    }

    getSettingsDbRef = () => {
        //CREO el puntero hacia configuracion
        return miFireBase.database().ref(this.context.dbPath).child("CwfSettings").child('autoScreenCapture')
    }

    getStorageRef = () => {
        return miFireBase.storage().ref().child('usersFiles').child(this.context.user.uid).child(this.context.activeDevice.imei).child('screenCaptures')
    }



    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })


        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        this.dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        this.dbRef.on('value', snap => {
            console.log("****ScreenCapture pictures dbRef.on VALUE****" + this.context.activeDevice.imei)

            let picturesAux = []


            snap.forEach((snap) => {

                let pic = ({
                    // original: '...', renderItem: renderPicture,
                    // thumbnail: '...', renderThumbInner: renderThumb,
                    granted: featureGranted,

                    key: snap.key,
                    url: snap.val().url,
                    fileName: snap.val().fileName,
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView),
                    deleteObject: this.deleteObject
                })
                if (this.context.user.isDemoUsr) pic.unRead = false


                checkFileExistsFromFireStorage(miFireBase, pic, this.deleteObject)
                picturesAux.push(pic)


            })
            sortArrayByFechaHora(picturesAux)//solo se hace al principio

            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = picturesAux[picturesAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(picturesAux, this.state.picturesOrderRecentFirst)

            this.setState({
                pictures: picturesAux,
                picturesFiltered: picturesAux,

                lastUpdate: lastUpdate,
                totalObjects: picturesAux.length,
            }, () => this.handleCalendarChange())//Para que tenga en cuenta si hay filterDate en la URL)

        })



        this.dbRef_RealTime = this.getRealTimeDbRef()
        this.dbRef_RealTime.remove();//elimino datos antiguos en la tabla

        this.dbRef_RealTime.on('value', snap => {
            console.log("****ScreenCapture dbRef_REALTIME.on VALUE****" + this.context.activeDevice.imei)

            if (snap.val()) { //Nuevos datos recibidos

                let comando = snap.val().comando;
                let respuesta = snap.val().respuesta;


                if (comando == "screen_ok") {
                    //FOTO RECIBIDA CORRECTAMENTE (en base64)
                    this.setState({
                        screenCaptureStatus: 0,
                        currentPictureBase64: respuesta,
                        alertMessage: { message: "", title: "", isError: false }
                    })
                    clearTimeout(this.timeOut);//apago timeout
                    UseWebServicePOST("operation=newCuota" + "&imei=" + this.context.activeDevice.imei + "&tipo=screensCaptures")

                } else if (comando == "screen_error") {
                    //ERROR AL INTENTAR TOMAR LA FOTO
                    this.setState({
                        screenCaptureStatus: 0,
                        alertMessage: { message: respuesta, title: "SCREEN CAPTURE ERROR ", isError: true, faIcon: "exclamation-circle" }
                    })
                    clearTimeout(this.timeOut);//apago timeout
                }


                console.log(snap.val());
            }

        })


        //CWF SETTINGS
        this.getSettingsDbRef().once('value', snap => {
            this.setState({ autoScreenCapture: snap.val() })
        });

    }


    componentWillUnmount() {
        this.dbRef.off()
        this.dbRef_RealTime.off()

        //vaciar fbTrash (elimino de firebase)
        this.state.fbTrash.map(objKey => {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase
        })

    }


    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.state.fbTrash.push(objKey)//Debo eliminar todo junto al salir (Por tener referencia en timpo real, sino se vuelve a cargar todo por cada eliminacion)

            const originalArray = this.state.pictures
            const filteredArray = this.state.picturesFiltered

            if (originalArray) {
                deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    pictures: originalArray,
                    picturesFiltered: filteredArray,

                    totalObjects: originalArray.length,
                })
            }
        }
    }

    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }


    handleCalendarChange = (date) => {

        //Aplica solo en la primera carga (cuando no habia ningun filtro fecha(osea la primer carga))
        const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
        if (dateFilterFromUrl && !this.state.filterDate) date = dateFilterFromUrl
        //------

        if (date) {

            let picturesFilteredAux = []

            this.state.pictures.map(pic => {
                if (pic.fecha == format(date, 'yyyy-MM-dd'))
                    picturesFilteredAux.push(pic)
            })


            sortArrayByRecentFirst(picturesFilteredAux, this.state.picturesOrderRecentFirst)

            this.setState({
                filterDate: date,
                picturesFiltered: picturesFilteredAux
            })

        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }



    handleOrderChange = () => {

        this.setState({//lo hago con ambas
            picturesFiltered: this.state.picturesFiltered.reverse(),
            picturesOrderRecentFirst: !this.state.picturesOrderRecentFirst
        })

    }


    handleShowAllClick = () => {

        this.setState({
            filterDate: null,
            picturesFiltered: this.state.pictures,
        })
    }


    handlePictureClick = (event) => {

        if (this.state.featureGranted) {
            let url = event.target.src
            openUrl(url)
        } else {
            this.props.history.push('/buy');
        }
    }



    handleTakeScreenCapture = () => {


        if (this.state.screenCaptureStatus == 0) {//Estado inicial, puedo tomar foto

            let comando = "screen"

            sendPushMessageToDevice(this.context.activeDevice.token, "screen")
            if (this.context.user.isDemoUsr) functionGetRandomRealTimeData(this.context.user.uid, this.context.activeDevice.imei, comando)

            this.timeOut = setTimeout(this.timeOutEvent, MAX_TIMEOUT_MS); //Enciendo el timeOut

            this.setState({
                screenCaptureStatus: 1,
                currentPictureBase64: null,
                alertMessage: { message: "", title: "", isError: false },
            })
        }

    }


    handleSaveFileClick = (save) => {


        if (this.state.currentPictureBase64) {

            if (save) {

                const current = this
                this.getStorageRef().child("pic_" + format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + ".jpg")
                    .putString(this.state.currentPictureBase64, 'base64').then(snap => {


                        snap.ref.getDownloadURL().then(function (downloadURL) {
                            current.getOriginalDbRef().push({ //Luego de subir el file base64 a fbStorage, inserto el registro en la BD	
                                url: downloadURL,
                                fecha: format(new Date(), 'yyyy-MM-dd'),
                                hora: format(new Date(), 'HH:mm:ss'),
                            });
                            UseWebServicePOST("operation=newDataHistory" + "&imei=" + current.context.activeDevice.imei + "&featureId=" + FEATURE_ID + "&newItems=" + 1)

                            current.setState({ currentPictureBase64: null, filterDate: null })
                        });


                    })

            } else {
                this.setState({ currentPictureBase64: null })
            }

        }
    }

    handelAutoScreenCaptureClick = () => {
        this.getSettingsDbRef().set(!this.state.autoScreenCapture).then(snap => {
            this.setState({ autoScreenCapture: !this.state.autoScreenCapture })
            alert("Changes Saved Successfully")
        })
    }


    timeOutEvent = () => {
        console.log("TIME OUT!!!")
        const { t } = this.props;

        if (this.state.featureGranted) {
            this.setState({
                screenCaptureStatus: 0,//Vuelvo a estado inicial
                alertMessage: { message: t("TimeOut.message"), title: t("TimeOut.title"), isWarning: true, faIcon: "hourglass-end" }
            })
        } else {
            this.setState({//Imagen ficticia!!
                screenCaptureStatus: 0,
                currentPictureBase64: image2
            })
        }
    }


    handlePictureClick = (item) => {
        if (this.state.featureGranted) {
            openUrl(item.url)
        } else {
            window.$('#ShowImageDialog').modal('hide')
            this.props.history.push('/buy');
        }
    }
    handlePictureDelete = (item) => {
        window.$('#ShowImageDialog').modal('hide')
        if (this.state.featureGranted) {
            this.deleteObject(item.key)
        } else {
            this.props.history.push('/buy');
        }
    }
    handleThumbClick = (picture) => {
        this.setState({ selectedPicture: picture })
        window.$('#ShowImageDialog').modal('show')
    }



    render() {
        const { t } = this.props;

        if (!this.state.pictures) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('ScreenCapture.title')}</h3>
                        <div className="container  text-center mt-4">
                            <Loader type="Rings" color="#fff" height={250} width={250} > </Loader> {t('ScreenCapture.loading')}
                        </div>
                    </div>
                </div>
            )


        } else {


            return (
                <div onClick={() => resizeNavBar()}>

                    <ImageDialog image={this.state.selectedPicture} handlePictureClick={this.handlePictureClick} handlePictureDelete={this.handlePictureDelete} />

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('ScreenCapture.title')}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    {this.state.featureGranted && <div><ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title={t('ScreenCapture.pictures')} limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ pictures: [], picturesFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                        <ShowNoItemsYet size={this.state.pictures.length} itemName={t('ScreenCapture.pictures')} /></div>}



                    {/* CURRENT PICTURE FILE */}
                    <div className="row my-2 ">
                        <div className="col-12">
                            <ShowCurrentPicture
                                pictureBase64={this.state.currentPictureBase64}
                                saveFileClick={this.handleSaveFileClick}
                                featureGranted={this.state.featureGranted}
                            />
                        </div>
                    </div>
                    {/* ************ */}




                    {/* LIVECAMERA SECTION */}
                    {!this.state.currentPictureBase64 &&
                        <div className="row my-2 ">
                            <div className="col-12">

                                {this.state.screenCaptureStatus == 0 ?
                                    <div className="text-center my-3">
                                        <button onClick={() => this.handleTakeScreenCapture(true)}  className="btn btn-sm btn-success greenShadow text-dark"><i className="fa fa-desktop fa-2x mr-2 py-1" />{t('ScreenCapture.takeCapture')}</button>
                                    </div>
                                    :
                                    <div className="d-flex mt-2 justify-content-center my-3">
                                        <span className="h6 mr-1 mt-1 text-danger"><i className='fa fa-spinner fa-spin fa-2x mr-1'/>{t('ScreenCapture.capturingScreen')}</span>
                                    </div>
                                }

                            </div>

                            <div className="col-12">
                                <AlertMessage data={this.state.alertMessage} faIcon="exclamation-triangle" />
                            </div>
                        </div>
                    }
                    {/* ******************** */}




                    {/* SWITCH autoLiveCamera */}
                    <div className="row my-2 ">
                        <div className="col-12">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switch1" checked={this.state.autoScreenCapture} onChange={this.handelAutoScreenCaptureClick} />
                                <label className="custom-control-label text-primary" htmlFor="switch1">{t('ScreenCapture.autoScreenCapture')}</label>
                            </div>
                        </div>
                    </div>
                    {/************/}



                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark blueShadow rounded-lg p-2">
                        <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.pictures} />
                        <ButtonShowAll size1={this.state.pictures} size2={this.state.picturesFiltered} handleShowAllClick={this.handleShowAllClick} />

                    </div>
                    {/* *********** */}



                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.picturesFiltered.length} filterName="date" showAll={this.handleShowAllClick} />



                    <div className="row">
                        <div className="col-12 mb-3">

                            <React.Fragment>
                                {this.state.picturesFiltered.length > 0 &&
                                    <div>
                                        <ButtonOrderFirst length={this.state.picturesFiltered.length} order={this.state.picturesOrderRecentFirst} handleOrderChange={this.handleOrderChange} />
                                        <ImageGallery2 items={this.state.picturesFiltered.slice(0, this.state.renderSize)} handleThumbClick={this.handleThumbClick} />
                                    </div>
                                }
                            </React.Fragment>


                            <ButtonGoUp arrayLength={this.state.picturesFiltered.length} maxLength={20} />
                            <ButtonShowMore arrayLength={this.state.picturesFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />


                        </div>
                    </div>

                </div>
            )

        }
    }
}
ScreenCapture.contextType = MiContexto
ScreenCapture = withTranslation()(ScreenCapture);
export default withRouter(ScreenCapture);



function ShowCurrentPicture(props) {

    let { pictureBase64, featureGranted } = props
    const { t } = useTranslation();


    if (pictureBase64) {

        let blur = ""
        if (!featureGranted) blur = "divBlur3"

        return (

            <div className='my-2'>

                <div className="p-2 d-flex justify-content-center">
                    <button className="btn btn-success mr-2" onClick={() => props.saveFileClick(true)}><i className="fa fa-check mr-1" />{t('ScreenCapture.save')}</button>
                    <button className="btn btn-danger" onClick={() => props.saveFileClick(false)}><i className="fa fa-times mr-1" />{t('ScreenCapture.discard')}</button>
                </div>

                <Zoom>
                    <div className="d-flex justify-content-center">
                        <img className={`img-fluid rounded border border-danger blob red ${blur}`} src={"data:image/png;base64," + pictureBase64} alt="" />
                        {/* {featureGranted ?
                            <img className="img-fluid rounded border border-warning" src={"data:image/png;base64," + pictureBase64} width='350' height='auto' alt="" />
                            :
                            <div className="rounded border border-warning"><Pixelify src={"data:image/png;base64," + pictureBase64} pixelSize={23} width={250} height={450} /></div>
                        } */}
                    </div>
                </Zoom>

            </div>
        )

    } else {
        return null
    }

}