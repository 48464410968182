import React, { Component } from 'react'
import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'
import {sendPushMessageToDevice} from '../CloudFunctions'


import { ButtonShowMore, ButtonShowAll, ShowFiltersResult, highlightFilter, ShowNoItemsYet, BuySubscriptionTag, GetCurrentLetterFromArray, ButtonGoUp, InputSearch, ShowFeatureNotSupported, ShowTotalAndLastUpdate } from '../CommonComponents'
import { resizeNavBar } from '../CommonFunctions'

//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import Moment from 'react-moment';

import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 12

const RENDER_SIZE_INCREMENT = 20

class AppsInstalled extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            //info de firebase database
            apps: null,
            appsFiltered: [],

            lastUpdate: null,

            filterAppByName: "",


            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 50
        }
    }



    getOriginalDbRef = () => {
        //CREO el puntero
        return miFireBase.database().ref(this.context.dbPath).child("appsInstaladas")
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)




        this.dbRef = this.getOriginalDbRef()


        this.dbRef.on('value', snap => {
            console.log("****AppsInstalled dbRef.on VALUE****" + this.context.activeDevice.imei)

            let appsAux = []
            snap.forEach((snap) => {
                if (snap.key != "ultVerificacion") {
                    appsAux.push({
                        key: snap.key,
                        compareTo: snap.val().nombre.toLowerCase(),//Valor que usare para comparar y ordenar

                        nombre: snap.val().nombre,
                        version: snap.val().version,
                        appIcon: snap.val().appIcon,
                        dir: snap.val().dir,
                        fecha: snap.val().fecha,
                        hora: snap.val().hora
                    })
                } else {
                    this.setState({ lastUpdate: snap.val().replace('|', ' ') })
                }
            })

            this.sortArray(appsAux)
            this.setState({
                apps: appsAux,
                appsFiltered: appsAux
            })

        })

        sendPushMessageToDevice(this.context.activeDevice.token, "getApps")//Para que envie actualizacion a firebase


    }



    componentWillUnmount() {
        this.dbRef.off()
    }



    sortArray = (targetArray) => {
        targetArray.sort((a, b) => {
            if (a.compareTo > b.compareTo) {
                return 1;
            }
            if (a.compareTo < b.compareTo) {
                return -1;
            }
            return 0;
        }
        );
    }


    getCurrentLetter = (array, index) => {
        let prevLetter = ""
        const currentLetter = array[index].nombre?.charAt(0).toUpperCase()

        if (index > 0)
            prevLetter = array[index - 1].nombre?.charAt(0).toUpperCase()

        if (prevLetter != currentLetter)
            return currentLetter
    }



    handleSearchInputChange = (e) => {

        const newFilterAppByName = e.target.value

        let appsFilteredAux = this.state.apps.filter(function (app) {
            return app.nombre.toLowerCase().trim().includes(newFilterAppByName.toLowerCase().trim())
        })

        this.setState({
            filterAppByName: newFilterAppByName,
            appsFiltered: appsFilteredAux
        })

    }


    handleShowAllClick = () => {
        let appsAux = this.state.apps//vuelvo a todos
        this.setState({
            appsFiltered: appsAux,
            filterAppByName: ""
        })
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }


    render() {

        const { t } = this.props

        if (!this.state.apps) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t("AppsInstalled.title")}</h3>
                        <div className="container  text-center mt-4">
                            <Loader type="Rings" color="#fff" height={250} width={250} > </Loader>{t("AppsInstalled.loading")} 
                        </div>
                    </div>
                </div>
            )


        } else {


            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t("AppsInstalled.title")}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.apps.length} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title="apps" fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ apps: [], appsFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                    <ShowNoItemsYet size={this.state.apps.length} itemName="Apps" />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark blueShadow rounded-lg p-2">
                        <InputSearch value={this.state.filterAppByName} onChange={this.handleSearchInputChange} placeholder="Search App" />
                        <ButtonShowAll size1={this.state.apps.length} size2={this.state.appsFiltered.length} handleShowAllClick={this.handleShowAllClick} />
                    </div>




                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterAppByName} filterResultSize={this.state.appsFiltered.length} filterName="app" showAll={this.handleShowAllClick} />


                    <div className="row ">
                        <div className="col-12 ">

                            <ul className="list-group list-group-flush ">

                                {this.state.appsFiltered.slice(0, this.state.renderSize).map((app, index) => {
                                    return (
                                        <div key={index}>
                                            <div>
                                                <GetCurrentLetterFromArray array={this.state.appsFiltered} index={index} />
                                                <ShowApp
                                                    app={app}
                                                    filter={this.state.filterAppByName}
                                                    featureGranted={this.state.featureGranted} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.appsFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.appsFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>
                    </div>



                </div>
            )

        }
    }
}
AppsInstalled.contextType = MiContexto
AppsInstalled = withTranslation()(AppsInstalled);
export default AppsInstalled;





function ShowApp(props) {

    const { t } = useTranslation();
    let { app, filter, featureGranted } = props

    //------
    let nombre = app.nombre
    let version = app.version
    let appIcon = app.appIcon
    let dir = app.dir
    let fecha = app.fecha
    let hora = app.hora
    //------


    const defaultIcon = "images/androidApp.png"
    const defaultIconDark = "images/androidApp_dark.png"
    const appIconCss = { width: '50px', height: '50px' }

    if (appIcon) {
        appIcon = "data:image/png;base64, " + appIcon;
    } else {
        appIcon = defaultIcon
    }


    // if (featureGranted) {

        let blur=""
        if(!featureGranted) blur="divBlur"

        //SE MUESTRA
        return (
            <Bounce>

                <li className={"list-group-item bg-dark text-white py-1"}>

                    <div className="row">

                        <div className={`col-12 col-md-4 d-flex ${blur}`}>
                            <img className="rounded-lg mr-2" style={appIconCss} src={appIcon} alt="" />
                            <div className="mt-4 text-light">{highlightFilter(nombre, filter)} </div>
                        </div>

                        <div className="col-12 col-md-5">
                            <div className="my-0"><span className="text-info mr-1 small">{t("AppsInstalled.version")}:</span> <span className="text-secondary small">{version?.substring(0, 4)}</span></div>
                            <div className="my-0"><span className="text-info mr-1 small">{t("AppsInstalled.installedOn")}:</span>  <span className="text-secondary small"><Moment date={fecha + " " + hora} format="dddd Do, MMMM YYYY, HH:mm" /></span></div>
                            {/* <p className="my-0"><span className="text-info mr-1">Path:</span> {dir}</p> */}

                        </div>

                    </div>

                </li>
            </Bounce>
        )

    // } else {

    //     //DEMO****
    //     return (
    //         <Bounce>
    //             <li className={"list-group-item bg-dark text-secondary  py-1"}>

    //                 <div className="row">

    //                     <div className="col-12 col-md-4">
    //                         <img className="rounded-lg mr-2" src={defaultIconDark} alt="" style={appIconCss} />
    //                         <span>{nombre.substring(0, 3)}...  </span><BuySubscriptionTag />
    //                     </div>

    //                     <div className="col-12 col-md-5 my-1">

    //                         <h6 className="my-0 mt-md-4 small">Version:<span className="mr-1"> {version?.substring(0, 4)}</span></h6>
    //                         <h6 className="my-0 small">Installed on:<span className="mr-1"> <Moment date={fecha + " " + hora} format="dddd Do, MMMM YYYY, HH:mm" /></span></h6>
    //                         {/* <p className="my-0">Path:<span className="mr-1"> {path}</span></p> */}

    //                     </div>
    //                 </div>

    //             </li>

    //         </Bounce>
    //     )
    // }


}
