import React, { Component } from 'react'


import MiContexto from '../MiContexto'
import miFireBase from '../config/config'


import { AlertMessage, ShowFeatureNotSupported } from '../CommonComponents'
import { resizeNavBar } from '../CommonFunctions'
import { functionGetRandomRealTimeData, sendPushMessageToDevice } from '../CloudFunctions'

//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"




const FEATURE_ID = 24
const MAX_TIMEOUT_MS = 10000

class Miscellaneous extends Component {

    constructor(props) {
        super(props)

        this.state = {

            lightIsOn: false,

            lightCode: 0,
            messageCode: 0,
            vibrationCode: 0,
        }
    }


    getRealTimeDbRef = () => {
        //CREO el puntero realtime
        return miFireBase.database().ref(this.context.dbPath).child("realTimeResponse")
    }

    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })


        this.dbRef_RealTime = this.getRealTimeDbRef()
        this.dbRef_RealTime.remove();//elimino datos antiguos en la tabla

        this.dbRef_RealTime.on('value', snap => {
            console.log("****Miscellaneous dbRef_REALTIME.on VALUE****" + this.context.activeDevice.imei)



            if (snap.val()) { //Nuevos datos recibidos

                let comando = snap.val().comando;
                let respuesta = snap.val().respuesta;


                //LIGHT------------------------
                if (comando == "luz") {
                    clearTimeout(this.timeOutLight)

                    if (respuesta == "on_ok") {
                        this.setState({
                            lightCode: 2,
                            lightIsOn: true
                        })

                    }

                    if (respuesta == "off_ok") {
                        this.setState({
                            lightCode: 22,
                            lightIsOn: false
                        })

                    }

                } else if (comando == "luz_error") {
                    clearTimeout(this.timeOutLight)
                    this.setState({
                        lightCode: 3,
                        lightIsOn: false
                    })




                    //VIBRATION----------------------------
                } else if (comando == "vibrar_ok") {
                    this.setState({
                        vibrationCode: 2
                    })
                    clearTimeout(this.timeOutVibration)


                } else if (comando == "vibrar_error") {
                    this.setState({
                        vibrationCode: 3
                    })
                    clearTimeout(this.timeOutVibration)



                    //TOAST MESSAGE----------------------
                } else if (comando == "msg_ok") {
                    this.setState({
                        messageCode: 2
                    })
                    clearTimeout(this.timeOutToast)


                } else if (comando == "msg_error") {
                    this.setState({
                        messageCode: 3
                    })
                    clearTimeout(this.timeOutToast)



                }
                console.log(snap.val());
            }
        })




    }

    componentWillUnmount() {
        this.dbRef_RealTime.off()
    }


    timeOutLightEvent = () => {
        console.log("TIMEOUT timeOutLightEvent")
        this.setState({ lightCode: 4 })

    }
    timeOutVibrationEvent = () => {
        console.log("TIMEOUT timeOutVibrationEvent")
        this.setState({ vibrationCode: 4 })
    }
    timeOutToastEvent = () => {
        console.log("TIMEOUT timeOutToastEvent")
        this.setState({ messageCode: 4 })
    }



    handleLightClick = () => {

        let comando=""
        if (this.state.lightIsOn) {

            comando="luz>off"
            sendPushMessageToDevice(this.context.activeDevice.token, comando)
            if (this.context.user.isDemoUsr) functionGetRandomRealTimeData(this.context.user.uid, this.context.activeDevice.imei, comando)
            
            this.setState({ lightCode: 11 })
        } else {

            comando="luz>on"
            sendPushMessageToDevice(this.context.activeDevice.token, comando)
            if (this.context.user.isDemoUsr) functionGetRandomRealTimeData(this.context.user.uid, this.context.activeDevice.imei, comando)

            this.setState({ lightCode: 1 })
        }


        this.timeOutLight = setTimeout(this.timeOutLightEvent, MAX_TIMEOUT_MS); //Enciendo el timeOut
    }


    
    handleVibrationClick = () => {

        let durationMs = "5000"
        let comando="vibrar>" + durationMs

        sendPushMessageToDevice(this.context.activeDevice.token,comando)
        if (this.context.user.isDemoUsr) functionGetRandomRealTimeData(this.context.user.uid, this.context.activeDevice.imei, comando)

        this.timeOutVibration = setTimeout(this.timeOutVibrationEvent, MAX_TIMEOUT_MS); //Enciendo el timeOut

        this.setState({
            vibrationCode: 1
        })
    }


    handleToastClick = () => {

        if (this.state.messageCode != 1) {
            const message = prompt("Put a message to send:", "")

            if (message?.trim().length > 0) {
                let comando="msg>" + message

                sendPushMessageToDevice(this.context.activeDevice.token, )
                if (this.context.user.isDemoUsr) functionGetRandomRealTimeData(this.context.user.uid, this.context.activeDevice.imei, comando)
                
                this.timeOutToast = setTimeout(this.timeOutToastEvent, MAX_TIMEOUT_MS); //Enciendo el timeOut

                this.setState({
                    messageCode: 1
                })
            }
        }
    }





    ShowLightButton = () => {
        const getButtonCode = () => {
            if (this.state.lightIsOn) {
                return <button className="btn btn-warning btn-block" onClick={this.handleLightClick}> <i className="fa fa-lightbulb-o mr-2" /> Turn OFF device Light </button>
            } else {
                return <button className="btn btn-primary btn-block" onClick={this.handleLightClick}> <i className="fa fa-lightbulb-o mr-2" /> Turn ON device Light </button>
            }

        }
        switch (this.state.lightCode) {
            case 0:
                return (
                    getButtonCode()
                )
            case 1:
                return (
                    <button className="btn btn-primary btn-block disabled"><i className="fa fa-commenting-o mr-2" />Sending light ON request<Loader type="ThreeDots" color="#e0a800" height={30} width={30} /></button>
                )

            case 11:
                return (
                    <button className="btn btn-primary btn-block disabled"><i className="fa fa-commenting-o mr-2" />Sending light OFF request<Loader type="ThreeDots" color="#e0a800" height={30} width={30} /></button>
                )
            case 2:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Device light turn ON successfully", title: "LIGHT IS ON! ", isError: false, faIcon: "check" }} />
                    </React.Fragment>
                )

            case 22:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Device light turn OFF successfully", title: "LIGHT IS OFF ", isError: false, faIcon: "check" }} />
                    </React.Fragment>
                )
            case 3:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Try again or later", title: "DEVICE LIGHT ERROR", isError: true, faIcon: "hourglass-end" }} />
                    </React.Fragment>
                )
            case 4:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Device must be connected to internet. Try again or later", title: "DEVICE LIGHT TIME OUT! ", isWarning: true, faIcon: "hourglass-end" }} />
                    </React.Fragment>
                )
            default:
                return null;
        }
    }






    ShowVibrationButton = () => {
        const getButtonCode = () => {
            return <button className="btn btn-primary btn-block" onClick={this.handleVibrationClick}><i className="fa fa-assistive-listening-systems mr-2" /> Vibrate 5 seconds </button>
        }
        switch (this.state.vibrationCode) {
            case 0:
                return (
                    getButtonCode()
                )
            case 1:
                return (
                    <button className="btn btn-primary btn-block disabled"><i className="fa fa-commenting-o mr-2" />Sending vibration<Loader type="ThreeDots" color="#e0a800" height={30} width={30} /></button>
                )
            case 2:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Vibration was sent successfully", title: "VIBRATION SENT ", isError: false, faIcon: "check" }} />
                    </React.Fragment>
                )
            case 3:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Try again or later", title: "ERROR SENDING VIBRATION ", isError: true, faIcon: "hourglass-end" }} />
                    </React.Fragment>
                )
            case 4:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Device must be connected to internet. Try again or later", title: "SEND VIBRATION TIME OUT! ", isWarning: true, faIcon: "hourglass-end" }} />
                    </React.Fragment>
                )
            default:
                return null;
        }
    }


    ShowMessageButton = () => {

        const getButtonCode = () => {
            return <button className="btn btn-primary btn-block" onClick={this.handleToastClick}><i className="fa fa-commenting-o mr-2" />Send toast message </button>
        }
        switch (this.state.messageCode) {
            case 0:
                return (
                    getButtonCode()
                )
            case 1:
                return (
                    <button className="btn btn-primary btn-block disabled"><i className="fa fa-commenting-o mr-2" />Sending message<Loader type="ThreeDots" color="#e0a800" height={30} width={30} /></button>
                )
            case 2:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Message was sent successfully", title: "TOAST MESSAGE SENT ", isError: false, faIcon: "check" }} />
                    </React.Fragment>
                )
            case 3:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Try again or later", title: "ERROR SENDING MESSAGE ", isError: true, faIcon: "hourglass-end" }} />
                    </React.Fragment>
                )
            case 4:
                return (
                    <React.Fragment>
                        {getButtonCode()}
                        <AlertMessage data={{ message: "Device must be connected to internet. Try again or later", title: "SEND MESSAGE TIME OUT! ", isWarning: true, faIcon: "hourglass-end" }} />
                    </React.Fragment>
                )
            default:
                return null;
        }
    }






    render() {


        return (
            <div onClick={()=>resizeNavBar()}>
                
                <div className="row">
                    <div className="col-12">
                        <h3>MISCELLANEOUS </h3>
                        <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    </div>
                </div>

                {this.state.featureGranted ?
                    <React.Fragment>


                        <div className="row">
                            <div className="col-12">
                                <h5 className="text-white rounded-lg">SEND REMOTE COMMANDS TO DEVICE</h5>
                            </div>
                        </div>


                        <div className="row">


                            <div className="col-12 my-2">
                                <this.ShowLightButton />
                            </div>

                            <div className="col-12 my-2">
                                <this.ShowVibrationButton />
                            </div>


                            <div className="col-12 my-2">
                                <this.ShowMessageButton />
                            </div>

                        </div>

                    </React.Fragment>

                    :
                    <AlertMessage data={{ message: "You must buy a subscription for access this feature", title: "BUY A SUBSCRIPTION ", isWarning: true, faIcon: "exclamation-triangle" }} />
                }



            </div>
        )




    }
}
export default Miscellaneous;
Miscellaneous.contextType = MiContexto
