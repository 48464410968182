
import React, { useState, useEffect, useContext } from 'react'
import UseWebService from '../../UseWebService'

import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';


import { useTranslation } from 'react-i18next';
import { load } from 'react-cookies';


export default function BuyResult(props) {

    const { t } = useTranslation();

    const { buyResult } = props
    const [decodedResult, setDecodedResult] = useState(null)

    const [reseller, setReseller] = useState(null)


    useEffect(() => {

        console.log("buyResult: ", buyResult)


        try {
            const array = buyResult.uid.split("|")

            const aEmail = array[0]
            const aPass = array[1]
            const aImei = array[2]
            const resellerId = array[3]

            setDecodedResult({ email: aEmail, pass: aPass, imei: aImei })

            if (resellerId && resellerId != '0') loadReseller(resellerId)
            


        } catch (error) {
            console.log("ERROR: ", error)
        }

    }, [])

    const loadReseller = async (resellerId) => {

        UseWebService("operation=getReseller&referId=" + resellerId).then(resp => {
            if (resp.statusCode == 200) {
                const reseller = resp.data[0]
                setReseller(reseller)
            }
        })

    }

    const handleControlPanel = () => {
        let url = `https://cp.catwatchful.com`
        if (decodedResult.pass && decodedResult.pass != '0')
            url = `https://cp.catwatchful.com/?usr=${btoa(`${decodedResult.email}|${decodedResult.pass}`)}`


        window.location.href = url
    }
    const handleDownloadApp = () => {
        window.location.href = 'https://catwatchful.pink/apk/getInstaller.php'
    }




    return (
        <React.Fragment>
            <div className='d-flex justify-content-center py-2' style={{ backgroundColor: "#171622" }}>
                <img src="./images/logo1_blanco.png" className="img-responsive" height={"50px"} width="auto" alt="CatWatchful" />
            </div>

            <div className='container ' style={{ marginTop: "40px" }} >

                {(buyResult.status === 'OK' && decodedResult) ?
                    <div>
                        {/* PAYMENT SUCCESSFUL!!! */}
                        <div className='text-center'>
                            <Bounce><div className='text-success h2'>{t('BuyResult.title')}<i className='fa fa-check-circle-o fa-1x text-success ml-1' /></div></Bounce>

                            <Fade>
                                <h6 className='text-uppercase text-success'>{t('BuyResult.title2')}</h6>
                                <h6 className='mt-2 text-uppercase '>{t('BuyResult.title3')}</h6>



                                <div className='d-flex justify-content-center' style={{ marginTop: "40px" }}>
                                    <div className='bg-dark px-4 pb-4 rounded text-left h5 border border-secondary' style={{ width: "450px" }}>

                                        <div className='text-center border-bottom border-secondary pt-2 mb-4 '>{t('BuyResult.credentials')}</div>

                                        <div><i className='fa fa-user mr-1 fa-2x mb-2' />{t('BuyResult.email')}: <span className='text-warning  ml-2'>{decodedResult.email}</span></div>
                                        {decodedResult.pass != '0' && <div><i className='fa fa-lock mr-1 my-1 fa-2x' />{t('BuyResult.password')}: <span className='text-warning ml-2'>{decodedResult.pass}</span></div>}
                                        {decodedResult.imei != '0' && <div><i className='fa fa-mobile mr-1 my-1 fa-2x ml-1' />{t('BuyResult.device')}: <span className='text-warning ml-2' >{decodedResult.imei}</span></div>}

                                        {reseller &&
                                            <div className='mt-2 text-secondary text-right'>{t('BuyResult.refer')}: {reseller.referCode} <img  className="rounded-circle border border-secondary" style={{ width: "45px", height: "45px" }} src={reseller.avatar}/></div>}


                                    </div>
                                </div>

                            </Fade>

                            <div className='text-center my-4'>
                                <button className='btn btn-primary ' onClick={handleControlPanel}><i className='fa fa-user mr-1' />{t('BuyResult.controlPanel')}</button>
                                <button className='btn btn-primary mx-2' onClick={handleDownloadApp}><i className='fa fa-download mr-1' />{t('BuyResult.download')}</button>
                            </div>

                            <div className='text-center mt-4  text-secondary'>
                                <i className='fa fa-envelope mr-1' />
                                {t('BuyResult.questions')} <a href="mailto: admin@catwatchful.com"><span className='' >{t('BuyResult.contactUs')}</span> </a>
                            </div>


                        </div>






                    </div>
                    :
                    <div className='text-center'>
                        {/* PAYMENT FAILLL*/}
                        <h1 className='text-danger '>{t('BuyResult.fail')}<i className='fa fa-frown-o fa-1x text-danger ml-2' /></h1>

                        <div className='border border-danger rounded p-2 bg-danger'>
                            <h5>{t('BuyResult.problem')}</h5>
                            <h5 className='mt-0'>{t('BuyResult.tryLater')}</h5>
                        </div>

                    </div>
                }



            </div>


            <footer>
                <div className=" footer small text-secondary">
                    <div className="mb-0 py-0">&copy; {new Date().getFullYear()} Catwatchful </div>
                    <div className="mb-0 py-0">{t('BuyResult.trademarks')}</div>
                </div>
            </footer>
        </React.Fragment>
    )






}
