import React, { Component } from 'react'
import { Link } from "react-router-dom";

import { AlertMessage } from '../../CommonComponents'
import miFireBase from '../../config/config.js'
import { UseWebServicePOST } from '../../UseWebService'

import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import Fade from 'react-reveal/Fade';

import cookie from 'react-cookies'

import { withTranslation } from 'react-i18next';

import './Login.css'

class Login extends Component {


    constructor(props) {
        super(props)

        this.state = {
            alertMessage: { message: "", title: "", isError: false },
            isLoading: false

        }

        this.emailRef = React.createRef()
        this.passwordRef = React.createRef()

        this.chkRef = React.createRef()
    }


    handlesubmit = (evt) => {

        evt.preventDefault()

        let email = this.emailRef.current.value
        let password = this.passwordRef.current.value

        let rememberUser = this.chkRef.current.checked


        if (email && password) {

            this.setState({ isLoading: true, alertMessage: { message: null } })

            miFireBase.auth().signInWithEmailAndPassword(email, password)
                .then((res) => {
                    //console.log(res.user)

                    if (rememberUser)
                        cookie.save('rememberUser', true, { maxAge: 2592000 })

                    UseWebServicePOST("operation=updateUltimoAcceso" + "&email=" + email + "&lastClientUsed=1")

                }).catch(error => {
                    this.setState({ alertMessage: { message: error.message, title: "Login.badLoginTitle", isError: true, faIcon: "exclamation-triangle" }, isLoading: false },)
                });



        } else {
            this.setState({ alertMessage: { message: "Login.badLoginMessage", title: "Login.badLoginTitle", isWarning: true, faIcon: "exclamation-triangle" } })

        }
    }



    render() {
        const { t } = this.props; //función de traducción

        return (
            <div className="col-12 col-md-6 mb-4">

                <div class='light x1'></div>
                <div class='light x2'></div>
                <div class='light x5'></div>
                <div class='light x6'></div>
                <div class='light x7'></div>
                <div class='light x8'></div>
                <div class='light x9'></div>


                <Fade>
                    <form onSubmit={this.handlesubmit} className=" blueShadow  py-2 px-4 bg-dark" style={{ border: "1px solid #2d2d2d", borderRadius: "15px" }} >

                        <legend className='text-uppercase text-center my-2'>{t('Login.login')}</legend>

                        <div className="input-group form-group mb-2" >

                            <div className="input-group-prepend" >
                                <span className="input-group-text " ><i className="fas fa-user"></i></span>
                            </div>
                            <input type="email" className="form-control " ref={this.emailRef} placeholder={t('Login.email')} autoComplete="username" required="required" />
                        </div>



                        <div className="input-group form-group ">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-key"></i></span>
                            </div>
                            <input type="password" className="form-control" ref={this.passwordRef} placeholder={t('Login.password')} autoComplete="current-password" required="required" />
                        </div>




                        <div className="custom-control custom-checkbox  pb-2 text-left" style={{ marginTop: "-10px" }}>
                            <input type="checkbox" className="custom-control-input" ref={this.chkRef} id="chkRemember" defaultChecked />
                            <label className="custom-control-label text-secondary pt-2 small" htmlFor="chkRemember">{t('Login.rememberPass')}</label>
                        </div>


                        {!this.state.isLoading && <button type="submit" className="btn btn-primary btn-block my-2 mb-4">{t('Login.entrar')}</button>}
                        {this.state.isLoading && <div className="text-center my-2"><Loader type="Puff" color="#2274cd" height={100} width={100} > </Loader>{t('Login.signIn')}</div>}


                        {/* Don't have an account? */}

                        <hr className='bg-secondary' />
                        <div className="d-flex justify-content-end mt-3">

                            <Link to="/signup"><div className=" medium text-secondary">{t('Login.crearCuenta')} </div></Link>
                            <span className='text-secondary ml-2'>|</span>
                            <Link to="/forgotpassword"><div className="mx-2 medium text-secondary">{t('Login.olvideContraseña')} </div></Link>
                        </div>




                    </form>
                </Fade>

                <AlertMessage data={this.state.alertMessage} />


            </div >
        )

    }

}

Login = withTranslation()(Login);
export default Login;




