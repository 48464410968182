import React, { Component, useState } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---
import { HashRouter, Link } from "react-router-dom";       

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { ShowFiltersResult, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, GetCurrentDateFromArray, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { resizeNavBar, checkFileExistsFromFireStorage, deleteFileFromFireStorage, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl } from '../CommonFunctions'


//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";


import ReactPlayer from 'react-player'

import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 5
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 10

class CallsRecording extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            callsRecording: null,
            callsRecordingFiltered: [],
            callsRecordingOrderRecentFirst: true,

            callsGroup: [],//Agrupacion de llamadas por nombre/numero


            filterDate: null,
            filterCallGroup: -1,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,


            renderSize: 50
        }

    }


    getOriginalDbRef = () => {
        //CREO el puntero

        if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
            return miFireBase.database().ref(this.context.dbPathDummy).child("MDgrabacionesLlamadas")

        return miFireBase.database().ref(this.context.dbPath).child("MDgrabacionesLlamadas")
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****callsRecording dbRef.once VALUE****" + this.context.activeDevice.imei)

            let callsRecordingAux = []
            let callsGroupAux = []


            snap.forEach((snap) => {

                const call = ({
                    key: snap.key,

                    nombre: snap.val().nombreContacto,
                    numero: snap.val().numero,
                    duration: snap.val().duration,
                    url: snap.val().url,
                    fileName: snap.val().fileName,
                    app: snap.val().app,
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0
                })


                checkFileExistsFromFireStorage(miFireBase, call, this.deleteObject)

                callsRecordingAux.push(call)
                this.addCallToGroup(call, callsGroupAux)



            })
            sortArrayByFechaHora(callsRecordingAux)//solo se hace al principio
            sortArrayByFechaHora(callsGroupAux)


            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = callsRecordingAux[callsRecordingAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(callsRecordingAux, this.state.callsRecordingOrderRecentFirst)

            this.setState({
                callsRecording: callsRecordingAux,
                callsRecordingFiltered: callsRecordingAux,

                callsGroup: callsGroupAux.reverse(),

                lastUpdate: lastUpdate,
                totalObjects: callsRecordingAux.length,
            })

        }).then(() => {
            //Luego que se hizo todo lo anterior...
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })

    }


    addCallToGroup = (call, currentGroup) => {
        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)

        let pos = 0
        if (call.nombre != "desconocido") {
            pos = currentGroup.map((s) => s.nombre).indexOf(call.nombre)
        } else {
            pos = currentGroup.map((s) => s.numero).indexOf(call.numero)
        }

        if (pos < 0) {
            //Creo un nuevo elemento en el chat
            currentGroup.push({
                nombre: call.nombre,
                numero: call.numero,
                cantCalls: 1,
                fecha: call.fecha,
                hora: call.hora,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cantCalls += 1
            currentGroup[pos].fecha = call.fecha
            currentGroup[pos].hora = call.hora
        }
        //----------------------------------

    }


    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.callsRecording
            const filteredArray = this.state.callsRecordingFiltered

            if (originalArray) {
                deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    callsRecording: originalArray,
                    callsRecordingFiltered: filteredArray,
                    totalObjects: originalArray.length
                })
            }

        }
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }

    handleCalendarChange = (date) => {

        if (date) {

            let callsRecordingFilteredAux = []

            this.state.callsRecording.map(call => {
                if (call.fecha == format(date, 'yyyy-MM-dd'))
                    callsRecordingFilteredAux.push(call)
            })


            sortArrayByRecentFirst(callsRecordingFilteredAux, this.state.callsRecordingOrderRecentFirst)

            this.setState({
                filterDate: date,
                filterCallGroup: -1,
                callsRecordingFiltered: callsRecordingFilteredAux
            })


        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }


    handleCallGroupChange = (event) => {

        const selectedCallGroup = event.target.value

        if (selectedCallGroup != -1) {

            let callsRecordingFilteredAux = []
            this.state.callsRecording.map(call => {

                if (call.nombre != "desconocido") {
                    if (call.nombre == selectedCallGroup)
                        callsRecordingFilteredAux.push(call)
                } else {
                    if (call.numero == selectedCallGroup)
                        callsRecordingFilteredAux.push(call)
                }

            })


            sortArrayByRecentFirst(callsRecordingFilteredAux, this.state.callsRecordingOrderRecentFirst)

            this.setState({
                callsRecordingFiltered: callsRecordingFilteredAux,
                filterCallGroup: selectedCallGroup,
                filterDate: null
            })


        } else {
            this.handleShowAllClick()

        }
    }


    handleOrderChange = () => {

        this.setState({
            callsRecordingFiltered: this.state.callsRecordingFiltered.reverse(),
            callsRecordingOrderRecentFirst: !this.state.callsRecordingOrderRecentFirst
        })
    }



    handleShowAllClick = () => {

        let callsRecordingAux = this.state.callsRecording//vuelvo a todos
        sortArrayByRecentFirst(callsRecordingAux, this.state.callsRecordingOrderRecentFirst)

        this.setState({
            callsRecordingFiltered: callsRecordingAux,
            filterDate: null,
            filterCallGroup: -1
        })
    }




    render() {
        const { t } = this.props; //función de traducción


        if (!this.state.callsRecording) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('CallsRecording.title')}</h3>
                        <div className="container  text-center mt-4">
                            <Loader type="Rings" color="#fff" height={250} width={250} > </Loader>{t('CallsRecording.loading')} 
                        </div>
                    </div>
                </div>
            )


        } else {


            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('CallsRecording.title')}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title={t('CallsRecording.titlee')} limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ callsRecording: [], callsRecordingFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                    <ShowNoItemsYet size={this.state.callsRecording.length} itemName={t('CallsRecording.itemName')} />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark blueShadow rounded-lg p-2">

                        <div>
                            <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.callsRecording} />
                            <SelectCallsGroup callsGroup={this.state.callsGroup} handleOnChange={this.handleCallGroupChange} value={this.state.filterCallGroup} />
                        </div>

                        <ButtonShowAll size1={this.state.callsRecording.length} size2={this.state.callsRecordingFiltered.length} handleShowAllClick={this.handleShowAllClick} />

                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.callsRecordingFiltered.length} filterName='date' showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterCallGroup} filterResultSize={this.state.callsRecordingFiltered.length} filterName={t('CallsRecording.contact')} showAll={this.handleShowAllClick} />


                    <div className="row">

                        <div className="col-12 mb-3">
                            {/* CALSS RECORDING-------- */}


                            <ButtonOrderFirst length={this.state.callsRecordingFiltered.length} order={this.state.callsRecordingOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                            {/* calls */}
                            <ul className="list-group list-group-flush" >
                                {this.state.callsRecordingFiltered.slice(0, this.state.renderSize).map((call, index) => {

                                    return (
                                        <div key={index} >
                                            <GetCurrentDateFromArray array={this.state.callsRecordingFiltered} index={index} itemName="calls" />
                                            <ShowCall
                                                call={call}
                                                featureGranted={this.state.featureGranted}
                                                deleteObject={this.deleteObject}
                                            />
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.callsRecordingFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.callsRecordingFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>


                    </div>

                </div>
            )

        }
    }
}
CallsRecording.contextType = MiContexto
CallsRecording = withTranslation()(CallsRecording);
export default withRouter(CallsRecording);


function SelectCallsGroup(props) {

    const { t } = useTranslation();
    const { callsGroup, value } = props

    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-phone fa-1x "></i></span>
            </div>

            <select value={value} className="form-control miSelect" id="selectCallsGroup" onChange={props.handleOnChange} >

                <option value={-1} >{t('CallsRecording.allCalls')}</option>

                {callsGroup.map((group, index) => {
                    let nombre = group.nombre

                    if (nombre == "desconocido") nombre = group.numero

                    return (
                        <option key={index} value={nombre}>{nombre}  ({group.cantCalls}) </option>
                    )
                })}

            </select>
        </div>)
}



function ShowCall(props) {
    const { t } = useTranslation();

    const [showControls, setShowControls] = useState(false)
    const [autoPlay, setAutoPlay] = useState(true)

    let { call, featureGranted } = props
    //------
    let key = call.key
    let nombre = call.nombre
    let numero = call.numero
    let duration = call.duration
    let url = call.url
    let fileName = call.fileName
    let app = call.app

    let fecha = call.fecha
    let hora = call.hora
    let unRead = call.unRead
    //-------


    if (nombre == "desconocido") nombre = numero

    let appIcon = ""
    if (app == "whatsapp") appIcon = "fa fa-whatsapp fa-2x mr-1"
    if (app == "comun") appIcon = "fa fa-phone-square fa-2x mr-1"


    if (featureGranted) {
        //SE MUESTRA
        return (

            <Bounce >
                <li className={"list-group-item bg-dark py-1"}>
                    <div className="row pt-1">
                        <div className="col-12">

                            {unRead && <span className="badge badge-danger">NEW!</span>}

                            <h6 className="text-info mb-1" ><i className={appIcon} />{nombre}</h6>
                            {nombre != numero && numero && <h6 className=" text-info font-italic mb-1 ">({numero})</h6>}

                            {showControls ?
                                <div className="d-flex justify-content-center mb-2">
                                    <ReactPlayer
                                        playing={autoPlay}
                                        url={url}
                                        controls={true}
                                        height='50px'
                                        volume={1}
                                        onPause={() => setAutoPlay(false)} onEnded={() => setAutoPlay(false)}
                                    />
                                </div>
                                :
                                <div className="d-flex">
                                    <button className="btn btn-success btn-sm mt-1 mr-2" style={{ width: "125px" }} onClick={() => setShowControls(true)}> <i className="fa fa-play mr-1" /> {t('CallsRecording.play')}</button>
                                    <div className="mt-3 small text-success"><i className="fa fa-hourglass-end fa-1x " /> {duration} {t('CallsRecording.seconds')}</div>
                                </div>

                            }


                        </div>

                        <div className="col-12">
                            <div className="text-secondary small d-flex justify-content-end">
                                <ShowItemDate fecha={fecha} hora={hora} />
                                <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => { props.deleteObject(key); setShowControls(false); setAutoPlay(true) }} />
                            </div>
                        </div>

                    </div>
                </li>
            </Bounce >
        )

    } else {


        //DEMO******
        return (

            <Bounce >
                <li className={"list-group-item bg-dark py-1"}>
                    <div className="row">
                        <div className="col-12">

                            {unRead && <span className="badge badge-danger">NEW!</span>}

                            <h6 className="text-info mb-1" ><i className={appIcon} />{nombre}</h6>
                            {nombre != numero && numero && <h6 className=" font-italic mb-1">({numero})</h6>}

                            <HashRouter>
                                <div>
                                    <BuySubscriptionTag message="Buy a subscription to listen this Call" />
                                    <div className="d-flex"><i className="fa fa-hourglass-end fa-1x mr-1 " /> {duration} {t('CallsRecording.seconds')}</div>
                                </div>
                                <Link to="/buy"> <div className="btn btn-outline-light btn-sm"><i className="fa fa-download mr-1 mb-1" />{t('CallsRecording.download')}</div></Link>
                            </HashRouter>


                        </div>

                        <div className="col-12">
                            <div className="text-secondary small d-flex justify-content-end">
                                <ShowItemDate fecha={fecha} hora={hora} />
                                <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
                            </div>
                        </div>

                    </div>
                </li>
            </Bounce>

        )
    }


}
