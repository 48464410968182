import { use } from 'i18next'
import './Buy.css'
import React, { useState, useEffect, useContext } from 'react'
import {useTranslation } from 'react-i18next';
import { set } from 'lodash'
import { de } from 'date-fns/locale'



export default function GetUser(props) {

    const { t } = useTranslation();

    const [currentSection, setCurrentSection] = useState(1)
    const reseller = props.reseller


    //Para el caso que el usuario ya exista y tenga que elegir dispositivos
    const currentProduct = props.currentProduct
    const [currentUser, setCurrentUser] = useState('')
    const [devices, setDevices] = useState([])
    //----------



    const handleCheckBoxClick = (e) => {
        window.open("https://catwatchful.com/main/terms-of-use/");
    }

    const changeCurrentSection = (section) => {
        setCurrentSection(section)

    }


    return (
        <React.Fragment>


            <div className="modal fade text-dark" id="getUserModal" tabIndex="-1" role="dialog" aria-labelledby="getUserModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        {currentSection === 1 && <SectionCreateAcccount />}
                        {currentSection === 2 && <SectionLoginAccount />}
                        {currentSection === 3 && <SectionSelectDevices currentUser={currentUser} devices={devices} devicesSupoorted={currentProduct.cantDevices} />}
                    </div>
                </div>

            </div>

        </React.Fragment>

    )



    function SectionLoginAccount() {

        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');

        const [terms, setTerms] = useState(true);

        const [warnings, setWarnings] = useState([]);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            setWarnings([])
        }, [])

        const selectMostRecentInstalledDevice = (devices) => {
            if (devices.length === 0) return '0'
            let mostRecentDevice = devices[0]
            for (let i = 1; i < devices.length; i++)
                if (devices[i].fecInst > mostRecentDevice.fecInst)
                    mostRecentDevice = devices[i]
            return mostRecentDevice.imei
        }

        const handleLogin = async (event) => {
            event.preventDefault()

            setWarnings([])
            setIsLoading(true);

            let warningsAux = []
            if (email.length === 0) {
                warningsAux.push(t('GetUser.emailReq'))
            } else if (!/\S+@\S+\.\S+/.test(email)) {
                warningsAux.push(t('GetUser.emailInvalid'))
            }
            if (password.trim().length === 0) {
                warningsAux.push(t('GetUser.passwordReq'))
            }
            if(!terms){
                warningsAux.push(t('GetUser.mustAcceptTerms'))
            }


            if (warningsAux.length === 0) {
                const resp = await props.handleLogin(email, password)
                setIsLoading(false);

                console.log("resp: ", resp)

                if (resp) {
                    if (resp.user) {
                        //USUARIO EXISTENTE!

                        const alreadyUsedDiscount = await props.handleCheckAlreadyUsedDiscount(email)

                        //Verifico si el usuario ya uso el codigo de descuento
                        if (reseller && alreadyUsedDiscount > 0) {
                            warningsAux.push(t('GetUser.alreadyUsedDiscount'))
                            setWarnings(warningsAux)
                            return
                        }
                        //-------------

                        let devices = []
                        if (resp.devices)
                            for (const [key, value] of Object.entries(resp.devices))
                                if (value)
                                    devices.push(value)

                        // //LE PERMITO AL USUARIO ELEGIR EN CUAL/es DISPOSITIVO HABILITAR LA SUSCRIPCION
                        // if(devices.length  > currentProduct.cantDevices) {
                        //     setCurrentUser(email)
                        //     setDevices(devices)
                        //     changeCurrentSection(3)//Tengo mas dispositivos de los que puedo comprar =>Debo seleccionar
                        // }else{
                        //     props.handleSaveUser(email, null, devices)
                        // }
                        // //******************************* */

                        let imei = selectMostRecentInstalledDevice(devices)//Selecciono el mas reciente instalado
                        props.handleSaveUser(email, null, imei)



                    } else {
                        warningsAux.push(t('GetUser.incorretEmailOrPass') )
                        setWarnings(warningsAux)
                    }

                } else {
                    warningsAux.push("ERROR, Try again or later")
                    setWarnings(warningsAux)
                }




            } else {
                setIsLoading(false);
                setWarnings(warningsAux)
            }
        }


        return (
            <React.Fragment>

                <div className="modal-header  my-0 py-2">
                    <h5 className="modal-title text-primary" id="exampleModalLongTitle"><i className='fa fa-user mr-1 ' />{t('GetUser.loginWithAccount')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form className='form-group' onSubmit={handleLogin}>
                    <div className="modal-body">
                        <div className="text-left">
                            <div className="input-with-icon">
                                <input
                                    className='form-control my-1'
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <i className='icon fa fa-envelope text-secondary' />
                            </div>
                            <div className="input-with-icon">
                                <input
                                    className='form-control mt-1'
                                    type="password"
                                    placeholder={t('GetUser.passPlaceHolder')}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <i className='icon fa fa-lock text-secondary' />
                            </div>

                            {warnings.length > 0 && warnings.map((warning, index) => {
                                return (
                                    <div key={index} className="alert alert-danger small mt-1 mb-0" role="alert"><i className="fa fa-warning" />  {warning}</div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="form-check mt-1">
                        <input type="checkbox" className="form-check-input" id="Check1" checked={terms} onChange={() => setTerms(!terms)} />
                        <label className="form-check-label cheked text-secondary small" htmlFor="Check1" onClick={handleCheckBoxClick}>{t('GetUser.agreeTerms')}</label>
                    </div>
                    {!isLoading ?
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('GetUser.btnClose')}</button>
                            <button type="submit" className="btn btn-primary"  >{t('GetUser.btnContinue')}</button>
                        </div>
                        :
                        <i className="spinner-border text-primary my-2" role="status" />
                    }
                    <div className='small' onClick={() => changeCurrentSection(1)}><span className='text-primary'><i className='fa fa-user-plus mr-1 ml-1' />{t('GetUser.createNewAccount')}</span></div>
                </form>

            </React.Fragment>
        )
    }


    function SectionCreateAcccount() {

        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [password2, setPassword2] = useState('');

        const [terms, setTerms] = useState(true);

        const [warnings, setWarnings] = useState([]);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            setWarnings([])
        }, [])

        const handleSaveUser = async (event) => {
            event.preventDefault()

            setIsLoading(true);

            let warningsAux = []
            if (email.length === 0) {
                warningsAux.push(t('GetUser.emailReq'))
            } else if (!/\S+@\S+\.\S+/.test(email)) {
                warningsAux.push(t('GetUser.emailInvalid'))
            }
            if (password.length === 0) {
                warningsAux.push(t('GetUser.passwordReq'))
            } else if (password.length < 6) {
                warningsAux.push(t('GetUser.passCharacters'))
            } else if (password !== password2) {
                warningsAux.push(t('GetUser.passNotMatch') )
            }
            if(!terms){
                warningsAux.push(t('GetUser.mustAcceptTerms'))
            }

            if (warningsAux.length === 0) {
                const resp = await props.handleUserExists(email, password)
                setIsLoading(false);

                console.log("resp: ", resp)

                if (resp === '0') {
                    //Usuario nuevo
                    props.handleSaveUser(email, password, '0')
                } else {
                    warningsAux.push(t('GetUser.emailExists'))
                    setWarnings(warningsAux)
                }


            } else {
                setIsLoading(false);
                setWarnings(warningsAux)
            }
        }



        return (

            <React.Fragment>

                <div className="modal-header  my-0 py-2">
                    <h5 className="modal-title text-primary" id="exampleModalLongTitle"><i className='fa fa-user-plus mr-1 ' />{t('GetUser.createAccountTitle')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form className='form-group' onSubmit={handleSaveUser}>
                    <div className="modal-body">
                        <div className="text-left">
                            <div className="input-with-icon">
                                <input
                                    className='form-control my-1'
                                    type="email"
                                    placeholder={t('GetUser.emailPlaceHorder')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <i className='icon fa fa-envelope text-secondary' />
                            </div>
                            <div className="input-with-icon">
                                <input
                                    className='form-control mt-3'
                                    type="password"
                                    placeholder={t('GetUser.chosePassPlaceHolder')}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <i className='icon fa fa-lock text-secondary' />
                            </div>
                            <div className="input-with-icon">
                                <input
                                    className='form-control mt-1'
                                    type="password"
                                    placeholder={t('GetUser.repatPassPlaceHolder')}
                                    value={password2}
                                    onChange={(e) => setPassword2(e.target.value)}
                                />
                                <i className='icon fa fa-lock text-secondary' />
                            </div>
                            {warnings.length > 0 && warnings.map((warning, index) => {
                                return (
                                    <div key={index} className="alert alert-danger small mt-1 mb-0" role="alert"><i className="fa fa-warning" />  {warning}</div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="form-check mt-1">
                        <input type="checkbox" className="form-check-input" id="Check1" checked={terms} onChange={() => setTerms(!terms)} />
                        <label className="form-check-label cheked text-secondary small" htmlFor="Check1" onClick={handleCheckBoxClick}>{t('GetUser.agreeTerms')}</label>
                    </div>
                    {!isLoading ?
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('GetUser.btnClose')}</button>
                            <button type="submit" className="btn btn-primary"  >{t('GetUser.btnContinue')}</button>
                        </div>
                        :
                        <i className="spinner-border text-primary my-2" role="status" />
                    }
                    <div className='small' onClick={() => changeCurrentSection(2)}>{t('GetUser.alreadyAccount')} <span className='text-primary'><i className='fa fa-user ml-2 mr-1' />{t('GetUser.login')}</span></div>
                </form>

            </React.Fragment>

        )

    }

    function SectionSelectDevices(props) {
        const currentUser = props.currentUser
        const devices = props.devices
        const devicesSupoorted = props.devicesSupoorted
        const [selectedDevices, setSelectedDevices] = useState([]);
        const [warnings, setWarnings] = useState([]);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            console.log("devices: ", devices)
            console.log("devicesSupoorted: ", devicesSupoorted)
        }, [])

        const handleContinueClick = () => {
            console.log("selectedDevices: ", selectedDevices)
        }

        if (!devices || devices.length === 0) return null
        return (
            <React.Fragment>
                <div className="modal-header  my-0 py-2">

                    <div className='text-left'>
                        <div><i className='fa fa-user' /> {currentUser}</div>
                        <div className="modal-title text-primary" id="exampleModalLongTitle"><i className='fa fa-mobile mr-1 ' />SELECT {currentProduct.cantDevices} DEVICES:</div>
                    </div>

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='text-left'>
                        {devices.map((device, index) => {
                            return (
                                <div key={index} className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id={`device${index}`} />
                                    <label className="form-check-label" htmlFor={`device${index}`}>
                                        {device.imei} <i className='fa fa-mobile ml-1' />
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary" onClick={handleContinueClick}  >CONTINUE</button>
                </div>
            </React.Fragment>
        )
    }
}