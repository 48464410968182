import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';


//reveal-effect
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

import { sleepTime, getRandomNumber, isValidDate, isToday, isYesterday } from '../CommonFunctions';


//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { ImagePixelated } from "react-pixelate"


import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

export function ImageGallery2(props) {

    const { items, isNow } = props;

    useEffect(() => {
        //console.log(gallery);
    }, []);

    if (!items) return null;
    if (!items.length) return null;
    console.log(items)
    //Se construye la matriz donde cada fila contiene los items de una misma fecha
    let matrixByDate = []
    let lastDate = ""
    let row = []
    for (let i = 0; i < items.length; i++) {
        let item = items[i]
        if (item.fecha != lastDate) {
            if (row.length) matrixByDate.push(row)
            row = []
            lastDate = item.fecha
        }
        row.push(item)
    }
    if (row.length) matrixByDate.push(row)
    //******************** */


    return (
        <div>

            {matrixByDate.map((row, index) => (

                <div key={index}>
                    <ShowDate fecha={row[0]?.fecha} size={row.length} isNow={isNow} />

                    <div className="d-flex justify-content-center fondoTransOscuro rounded py-2">
                        <div className="row col-12 px-0 justify-content-center" style={{ zIndex: 0 }}>
                            {row.map((item, index) => (
                                <ThumbItem key={index} item={item} handleThumbClick={props.handleThumbClick} />
                            ))}
                        </div>

                    </div>

                </div>
            ))}


        </div>
    );


    function ShowDate(props) {
        const { t } = useTranslation();
        const { fecha, size, isNow } = props

        const getCounterBadgeTag = () => { return (<span style={{ fontSize: "11px" }} className="text-dark badge badge-warning ml-1"> {size + " " + t("Pictures")}  </span>) }


        if (isValidDate(fecha)) {

            let itemYear = fecha.split("-")[0]
            let actualYear = new Date().getFullYear()
            const style = "text-warning mt-4 mb-1 h5"

            if (isNow)
                return <div className={style}> {t("isNow")}   {getCounterBadgeTag()}</div>

            if (isToday(fecha))
                return <div className={style}>{t("isToday")}   {getCounterBadgeTag()}</div>

            if (isYesterday(fecha))
                return <div className={style}>{t("isYesterday")} {getCounterBadgeTag()} </div>

            if (itemYear == actualYear)
                return <div className={style}><Moment date={fecha} format="dddd Do, MMMM" /> {getCounterBadgeTag()} </div>

            return <div className={style}><Moment date={fecha} format="dddd Do, MMMM YYYY" /> {getCounterBadgeTag()} </div>

        } else {
            return <h5 className="text-warning mt-4 mb-0">{fecha}  <span>{getCounterBadgeTag()}</span></h5>
        }

    }

}


export function ThumbItem(props) {
    const { item, handleThumbClick, singleItem } = props;
    const { t } = useTranslation();

    if (!item) return null;
    if (!item.url) return null;

    let aWidth = "210px"
    let aHeight = "210px"

    if (!singleItem) {
        if (window.innerWidth < 1024) {
            aWidth = "150px"
            aHeight = "150px"
        }
        if (window.innerWidth < 768) {
            aWidth = "70px"
            aHeight = "70px"
        }
    } else {
        // aWidth = "100%"
        // aHeight = "100%"
    }

    let imgStyle = {
        width: aWidth,
        height: aHeight,
        objectFit: 'cover'
    }
    if (item.unRead)
        imgStyle.border = "2px solid #dc3545"

    const unReadTag = () => {
        if (item.unRead)
            return <div style={{ lineHeight: "3px", paddingTop: "5px" }} className='text-center'><span style={{ lineHeight: "7px" }} className="badge badge-danger my-0">{t("New")}</span></div>
        return null
    }


    const handleClick = () => {
        handleThumbClick && handleThumbClick(item)
    }

    let blur = ""
    if (!item.granted)
        window.innerWidth > 768 ? blur = "divBlur2" : blur = "divBlur"

    let pulseEffect = ""
    if (item.unRead)
        pulseEffect = "blob red"

    if (getMediaType(item) == "image") {
        return (
            <div>
                {unReadTag()}
                <div className={`bg-dark rounded ${blur} ${pulseEffect}`} style={{ marginLeft: "2px", marginRight: "2px", marginTop: "2px", marginBottom: "2px" }} onClick={handleClick} >
                    <Fade delay={getRandomNumber(50, 1000)}><img src={item.url} className='rounded' style={imgStyle} /></Fade>
                </div>
            </div>
        )
    }
    if (getMediaType(item) == "video") {
        return (
            <div>
                {unReadTag()}
                <div className={`bg-dark rounded ${blur} ${pulseEffect}`} style={{ marginLeft: "2px", marginRight: "2px", marginTop: "2px", marginBottom: "2px" }} onClick={handleClick} >
                    <div style={{ position: "absolute", zIndex: 2 }}><span className='badge badge-primary'>video <i className='fa fa-video-camera' /></span></div>
                    <div style={{ position: "relative" }}><Fade delay={getRandomNumber(50, 1000)}><video autoPlay muted src={item.url} className='rounded' style={imgStyle} /></Fade></div>
                </div>
            </div>
        )
    }
    if (getMediaType(item) == "document") {
        return (
            <div>
                {unReadTag()}
                <div className={`bg-dark rounded ${blur} ${pulseEffect}`} style={{ marginLeft: "2px", marginRight: "2px", marginTop: "2px", marginBottom: "2px" }} onClick={handleClick}>
                    <div style={{ position: "absolute", zIndex: 2 }}><span className='badge badge-dark'>document <i className='fa fa-file-text' /></span></div>
                    <div style={{ position: "relative" }}><Fade delay={getRandomNumber(50, 1000)}><iframe src={item.url} className='rounded' style={imgStyle} /></Fade></div>
                </div>
            </div>
        )
    }
    if (getMediaType(item) == "audio") {
        return (
            <div>
                {unReadTag()}
                <div className={`bg-dark rounded ${blur} ${pulseEffect}`} style={{ marginLeft: "2px", marginRight: "2px", marginTop: "2px", marginBottom: "2px" }} onClick={handleClick} >
                    <div style={imgStyle} ><span className='badge badge-success'>audio <i className='fa fa-play-circle' /></span></div>
                </div>
            </div>
        )
    }


    return null


}


export function ImageDialog(props) {

    const { t } = useTranslation();
    const { image, folderName } = props

    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        const loadImage = async () => {
            if (image) {
                setIsLoading(true)
                await sleepTime(getRandomNumber(500, 1000))
                setIsLoading(false)
            }
        }

        loadImage()
    }, [image])


    if (!image) return null

    const getMediaElementContent = () => {
        let blur = ""
        if (!image.granted) blur = "divBlur3"

        if (getMediaType(image) == "image") {
            return <img src={image.url} alt="" className={`img-fluid ${blur}`} onClick={() => props.handlePictureClick(image)} />
        }
        if (getMediaType(image) == "video") {
            return <video autoPlay controls src={image.url} className={`img-fluid ${blur}`} onClick={() => props.handlePictureClick(image)} />
        }
        if (getMediaType(image) == "audio") {
            return <audio autoPlay controls src={image.url} className={`img-fluid ${blur}`} onClick={() => props.handlePictureClick(image)} />
        }
        if (getMediaType(image) == "document") {
            return <iframe style={{ height: "600px" }} src={image.url} className={`img-fluid ${blur}`} onClick={() => props.handlePictureClick(image)} />
        }
    }

    return (

        <div className="modal fade" id="ShowImageDialog" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className=''>
                            <div className="modal-title" id="exampleModalLongTitle" style={{ wordWrap: 'break-word' }}>{image.fileName}</div>
                            {folderName && <div className='text-primary text-success text-uppercase font-weight-bold'>{folderName}</div>}
                            <div style={{ fontSize: "14px" }} className='badge badge-warning text-dark'>
                                {isToday(image.fecha) ?
                                    <div>{t('isToday')} <Moment date={image.fecha + " " + image.hora} format="h:mm a" /></div>
                                    :
                                    isYesterday(image.fecha) ?
                                        <div>{t('isYesterday')} <Moment date={image.fecha + " " + image.hora} format="h:mm a" /></div>
                                        :
                                        <Moment date={image.fecha + " " + image.hora} format="dddd Do, MMMM, h:mm a" />}

                            </div>
                        </div>

                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className='text-center'>
                            {isLoading || !image ?
                                <div>
                                    <div className='d-flex justify-content-center'>
                                        <i className='fa fa-spinner fa-3x fa-spin text-primary' />
                                    </div>
                                    <div className='text-center small mt-2'>{t('ImageGallery2.cargandoImg')}</div>
                                </div>
                                :
                                <div >

                                    {image.granted ?
                                        <Fade>
                                            {/* <img src={image.url} alt="" className='img-fluid' onClick={() => props.handlePictureClick(image)} /> */}
                                            {getMediaElementContent()}
                                        </Fade>
                                        :
                                        <Fade>
                                            <div onClick={() => props.handlePictureClick(image)} >
                                                {/* <div className="alert alert-danger">{t('DevicePictures.bloqueado')}</div> */}
                                                {/* <ImagePixelated src={image.url} pixelSize={15} width={350} height={350} fillTransparencyColor={"grey"} /> */}
                                                {/* <img src={image.url} alt="" className='img-fluid divBlur3' /> */}
                                                {getMediaElementContent()}
                                                <button type="button" className="btn btn-danger btn-block" data-dismiss="modal" >{t('ImageGallery2.desbloquear')}</button>
                                            </div>
                                        </Fade>
                                    }


                                    <div className='mt-3'>
                                        {props.handlePictureDelete && <i onClick={() => props.handlePictureDelete(image)} className='fa fa-trash fa-2x text-danger' />}
                                        <i onClick={() => props.handlePictureClick(image)} className='fa fa-download fa-2x text-primary mx-4' />
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('ImageGallery2.cerrar')}</button>



                    </div>
                </div>
            </div>
        </div>
    )

}

const getMediaType = (file) => {
    // let isImage =(item.url.includes(".jpg") || item.url.includes(".png") || item.url.includes(".jpeg") || item.url.includes(".gif") || item.url.includes(".bmp") || item.url.includes(".webp") || item.url.includes(".svg"))
    // let isVideo =  (item.url.includes(".mp4") || item.url.includes(".avi") || item.url.includes(".mov") || item.url.includes(".mkv") || item.url.includes(".webm") || item.url.includes(".flv") || item.url.includes(".3gp") || item.url.includes(".wmv") || item.url.includes(".mpg") || item.url.includes(".mpeg") || item.url.includes(".m4v") || item.url.includes(".ogv") || item.url.includes(".m2v") || item.url.includes(".m2ts") || item.url.includes(".ts") || item.url.includes(".mts") || item.url.includes(".mxf") || item.url.includes(".vob") || item.url.includes(".divx") || item.url.includes(".xvid") || item.url.includes(".asf") || item.url.includes(".rm") || item.url.includes(".rmvb"))
    // let isAudio =  (item.url.includes(".mp3") || item.url.includes(".wav") || item.url.includes(".ogg") || item.url.includes(".flac") || item.url.includes(".m4a") || item.url.includes(".aac") || item.url.includes(".wma") || item.url.includes(".aiff") || item.url.includes(".au") || item.url.includes(".m3u") || item.url.includes(".m3u8") || item.url.includes(".pls") || item.url.includes(".asx") || item.url.includes(".xspf") || item.url.includes(".wpl") || item.url.includes(".zpl") || item.url.includes(".kpl") || item.url.includes(".qpl") || item.url.includes(".pla") || item.url.includes(".plc") || item.url.includes(".plb") || item.url.includes(".pl") || item.url.includes(".opus"))
    // let isDocument =  (item.url.includes(".pdf") || item.url.includes(".doc") || item.url.includes(".docx") || item.url.includes(".xls") || item.url.includes(".xlsx") || item.url.includes(".ppt") || item.url.includes(".pptx") || item.url.includes(".txt") || item.url.includes(".rtf") || item.url.includes(".csv") || item.url.includes(".xml") || item.url.includes(".json") || item.url.includes(".html") || item.url.includes(".htm") || item.url.includes(".php") || item.url.includes(".js") || item.url.includes(".css") || item.url.includes(".java") || item.url.includes(".c") || item.url.includes(".cpp") || item.url.includes(".cs") || item.url.includes(".vb") || item.url.includes(".py") || item.url.includes(".sql") || item.url.includes(".pl"))
    if (file && file.url) {

        if (file.url.includes("data:image") || file.url.includes(".jpg") || file.url.includes(".png") || file.url.includes(".jpeg") || file.url.includes(".gif") || file.url.includes(".bmp") || file.url.includes(".webp") || file.url.includes(".svg")) {
            return "image"
        }
        if (file.url.includes(".mp4") || file.url.includes(".avi") || file.url.includes(".mov") || file.url.includes(".mkv") || file.url.includes(".webm") || file.url.includes(".flv") || file.url.includes(".3gp") || file.url.includes(".wmv") || file.url.includes(".mpg") || file.url.includes(".mpeg") || file.url.includes(".m4v") || file.url.includes(".ogv") || file.url.includes(".m2v") || file.url.includes(".m2ts") || file.url.includes(".ts") || file.url.includes(".mts") || file.url.includes(".mxf") || file.url.includes(".vob") || file.url.includes(".divx") || file.url.includes(".xvid") || file.url.includes(".asf") || file.url.includes(".rm") || file.url.includes(".rmvb")) {
            return "video"
        }
        if (file.url.includes(".mp3") || file.url.includes(".wav") || file.url.includes(".ogg") || file.url.includes(".flac") || file.url.includes(".m4a") || file.url.includes(".aac") || file.url.includes(".wma") || file.url.includes(".aiff") || file.url.includes(".au") || file.url.includes(".m3u") || file.url.includes(".m3u8") || file.url.includes(".pls") || file.url.includes(".asx") || file.url.includes(".xspf") || file.url.includes(".wpl") || file.url.includes(".zpl") || file.url.includes(".kpl") || file.url.includes(".qpl") || file.url.includes(".pla") || file.url.includes(".plc") || file.url.includes(".plb") || file.url.includes(".pl") || file.url.includes(".opus")) {
            return "audio"
        }

        return "document"

    } else {
        return null
    }
}





