import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";


import miFireBase from '../../config/config.js'
import { AlertMessage } from '../../CommonComponents'

import Fade from 'react-reveal/Fade';


//Loading spinner
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { withTranslation, useTranslation } from 'react-i18next';
import {DEFAULT_HOST_API_URL} from '../../UseWebService'



class SignUp extends Component {

    constructor(props) {
        super(props)

        this.emailRef = React.createRef()
        this.password1Ref = React.createRef()
        this.password2Ref = React.createRef()


        this.state = ({
            alertMessage: { message: "", title: "", isError: false },
            isLoading: false
        })

        this.handlesubmit = this.handlesubmit.bind(this)

    }




    handlesubmit(evt) {
        const { t } = this.props; //función de traducción

        evt.preventDefault()

        let email = this.emailRef.current.value
        let password1 = this.password1Ref.current.value
        let password2 = this.password2Ref.current.value



        if (email) {
            if (password1 === password2) {

                this.setState({ isLoading: true })

                miFireBase.auth().createUserWithEmailAndPassword(email, password1)
                    .then((fbResp) => {

                        const requestOptions = { method: 'POST' }
                        const url = `${DEFAULT_HOST_API_URL}operation=newUser&email=${email}&pass=${password1}&firebase_uid=${fbResp.user.uid}`

                        //miFireBase.auth().signOut()

                        fetch(url, requestOptions).then(wsResp => {

                            wsResp.json().then(resJson => {

                                if (resJson.statusCode === 200) {

                                    console.log("Usuario creado")
                                    fbResp.user.sendEmailVerification()
                                    //this.props.history.push("/")
                                    alert(t('SignUp.singOk'))


                                } else {
                                    console.log("Error en consulta " + resJson.statusCode + " " + resJson.errorMessage)

                                    fbResp.user.delete()
                                    alert(t('SignUp.badSignUp') + resJson.statsusMessage)
                                }

                            }).catch(jsonError => {
                                console.log(jsonError)

                                fbResp.user.delete()
                                alert(t('SignUp.badSignUp') + jsonError.message)
                            })


                        }
                        ).catch(fecthError => {
                            console.log(fecthError)

                            fbResp.user.delete()
                            alert(t('SignUp.badSignUp') + fecthError.message)
                        })





                    })
                    .catch(error => (
                        this.setState({ alertMessage: { message: error.message, title: t('SignUp.badSignUp'), isError: true, faIcon: "exclamation-triangle" }, isLoading: false })

                    ))
            } else {
                this.setState({ alertMessage: { message: t('SignUp.passNotMatch'), title: t('SignUp.badSignUp'), isError: true, faIcon: "exclamation-triangle" }, isLoading: false })
            }
        } else {
            this.setState({ alertMessage: { message: t('SignUp.putEmail'), title: t('SignUp.badSignUp'), isError: true, faIcon: "exclamation-triangle" }, isLoading: false })
        }

    }


    render() {
        const { t } = this.props; //función de traducción

        return (
            <div className="col-12 col-md-6 mb-4">


                <Fade>
                <form onSubmit={this.handlesubmit} className=" blueShadow  py-2 px-4" style={{border:"1px solid #2d2d2d",borderRadius:"15px"}} >
                        <legend className='text-uppercase text-center my-2'>{t('SignUp.title')}</legend>

                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" ><i className="fas fa-user"></i></span>
                            </div>
                            <input type="email" className="form-control " ref={this.emailRef} placeholder={t('SignUp.email')} autoComplete="username" required="required" />
                        </div>



                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" ><i className="fas fa-key"></i></span>
                            </div>
                            <input type="password" className="form-control" ref={this.password1Ref} placeholder={t('SignUp.password')} autoComplete="current-password" required="required" />

                            <div className="input-group form-group my-1">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" ><i className="fas fa-key"></i></span>
                                </div>
                                <input type="password" className="form-control" ref={this.password2Ref} placeholder={t('SignUp.repeatPassword')} autoComplete="current-password" required="required" />
                            </div>
                        </div>


                        {!this.state.isLoading && <div className="text-center mt-2">
                            <Link to="/"><button className="btn btn-outline-primary  mx-2" style={{width:"130px"}}>{t('SignUp.back')}</button></Link>
                            {!this.state.isLoading && <button type="submit" className="btn btn-primary my-2 " style={{width:"130px"}}>{t('SignUp.signUp')}</button>}


                        </div>}
                        {this.state.isLoading && <div className="text-center my-2"><Loader type="Puff" color="#fff" height={100} width={100} > </Loader>{t('SignUp.lsignUp')} </div>}


                    </form>
                </Fade>

                <AlertMessage data={this.state.alertMessage} />


            </div >

        )
    }
}

SignUp = withTranslation()(SignUp);
export default withRouter(SignUp); 