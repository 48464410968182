import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { getIcoByApp,ButtonDeleteFeatureData, ShowFiltersResult, highlightFilter, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, GetCurrentDateFromArray, ButtonGoUp, ButtonShowMore, ButtonShowAll, InputSearch, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { resizeNavBar, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl, getRandomNumber } from '../CommonFunctions'


//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


//reveal-effect
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";

import { useTranslation, withTranslation } from 'react-i18next';

import { IA_Component } from '../IA'

const FEATURE_ID = 9
const LIMIT_TO_LAST = 5000

const RENDER_SIZE_INCREMENT = 10


class Notifications extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            notifications: null,
            notificationsFiltered: [],
            notificationsOrderRecentFirst: true,

            notificationPkgGroup: [],//Agrupacion de notificaciones por nombrePaquete
            notificationTitleGroup: [],//Agrupacion de notificaciones por nombrePaquete


            filterDate: null,
            filterNotificationPkgGroup: -1,
            filterNotificationTitleGroup: -1,
            filterNotificationByMessage: "",


            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 50

        }


    }


    getOriginalDbRef = () => {
        //CREO el puntero

        if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
            return miFireBase.database().ref(this.context.dbPathDummy).child("notifications")

        return miFireBase.database().ref(this.context.dbPath).child("notifications")
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****notificacions dbRef.once VALUE****" + this.context.activeDevice.imei)

            let notificationsAux = []
            let notificationsPkgGroupAux = []
            let notificationsTitleGroupAux = []


            snap.forEach((snap) => {

                const not = ({
                    key: snap.key,

                    title: snap.val().title,
                    message: snap.val().message,
                    paquete: snap.val().paquete?.replace("com.", ""),
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0
                })
                if (!not.title) not.title = "untitled"


                if (!this.notificationExist(not, notificationsAux)) {
                    notificationsAux.push(not)//A veces las notificaciones se repiten (misma app misma not mismo tiempo)
                    this.addNotificationToPkgGroup(not, notificationsPkgGroupAux)
                    this.addNotificationToTitleGroup(not, notificationsTitleGroupAux)
                } else {
                    this.deleteObject(not.key)
                }



            })
            sortArrayByFechaHora(notificationsAux)//solo se hace al principio
            sortArrayByFechaHora(notificationsPkgGroupAux)
            sortArrayByFechaHora(notificationsTitleGroupAux)


            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = notificationsAux[notificationsAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(notificationsAux, this.state.notificationsOrderRecentFirst)

            this.setState({
                notifications: notificationsAux,
                notificationsFiltered: notificationsAux,

                notificationPkgGroup: notificationsPkgGroupAux.reverse(),
                notificationTitleGroup: notificationsTitleGroupAux.reverse(),

                lastUpdate: lastUpdate,
                totalObjects: notificationsAux.length,
            })

        }).then(() => {
            //Luego que se hizo todo lo anterior...
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })


    }



    notificationExist = (not, array) => {
        const targetValue = not.paquete + not.message + not.title + not.fecha + not.hora
        for (let i = 0; i < array.length; i++) {
            const n = array[i]
            const currentValue = n.paquete + n.message + n.title + n.fecha + n.hora
            if (currentValue == targetValue)
                return true
        }
        return false
    }



    addNotificationToPkgGroup = (not, currentGroup) => {
        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)
        let pos = currentGroup.map((n) => n.paquete).indexOf(not.paquete)
        if (pos < 0) {
            //Creo un nuevo elemento en el chat
            currentGroup.push({
                paquete: not.paquete,
                cantNots: 1,
                fecha: not.fecha,
                hora: not.hora,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cantNots += 1
            currentGroup[pos].fecha = not.fecha
            currentGroup[pos].hora = not.hora
        }
        //----------------------------------
    }


    addNotificationToTitleGroup = (not, currentGroup) => {
        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)
        let pos = currentGroup.map((n) => n.title).indexOf(not.title)
        if (pos < 0) {
            //Creo un nuevo elemento en el chat
            currentGroup.push({
                title: not.title,
                cantNots: 1,
                fecha: not.fecha,
                hora: not.hora,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cantNots += 1
            currentGroup[pos].fecha = not.fecha
            currentGroup[pos].hora = not.hora
        }
        //----------------------------------
    }







    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.notifications
            const filteredArray = this.state.notificationsFiltered

            if (originalArray) {
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    notifications: originalArray,
                    notificationsFiltered: filteredArray,
                    totalObjects: originalArray.length
                })
            }
        }
    }



    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }


    handleCalendarChange = (date) => {

        if (date) {

            let notificationsFilteredAux = []

            this.state.notifications.map(not => {
                if (not.fecha == format(date, 'yyyy-MM-dd'))
                    notificationsFilteredAux.push(not)
            })


            sortArrayByRecentFirst(notificationsFilteredAux, this.state.notificationsOrderRecentFirst)

            this.setState({
                filterDate: date,
                filterNotificationPkgGroup: -1,
                filterNotificationTitleGroup: -1,
                notificationsFiltered: notificationsFilteredAux,
                filterNotificationByMessage: "",
            })


        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }




    handleNotificationPkgGroupChange = (event) => {
        const selectedNotificationPkgGroup = event.target.value
        if (selectedNotificationPkgGroup != -1) {
            this.filterByPkg(selectedNotificationPkgGroup)
        } else {
            this.handleShowAllClick()
        }
    }


    handleNotificationTitleGroupChange = (event) => {
        const selectedNotificationTitleGroup = event.target.value
        if (selectedNotificationTitleGroup != -1) {
            this.filterByTitle(selectedNotificationTitleGroup)
        } else {
            this.handleShowAllClick()
        }
    }

    filterByTitle = (aTitle) => {
        let notificationsFiltered = []
        this.state.notifications.map(not => {

            if (not.title == aTitle)
                notificationsFiltered.push(not)

        })
        sortArrayByRecentFirst(notificationsFiltered, this.state.notificationsOrderRecentFirst)

        this.setState({
            notificationsFiltered: notificationsFiltered,
            filterNotificationTitleGroup: aTitle,
            filterNotificationPkgGroup: -1,
            filterDate: null,
            filterNotificationByMessage: "",
        })

        window.scrollTo({ behavior: 'smooth', top: 228 })

    }


    filterByPkg = (aPkg) => {
        let notificationsFiltered = []
        this.state.notifications.map(not => {

            if (not.paquete == aPkg)
                notificationsFiltered.push(not)

        })
        sortArrayByRecentFirst(notificationsFiltered, this.state.notificationsOrderRecentFirst)
        this.setState({
            notificationsFiltered: notificationsFiltered,
            filterNotificationPkgGroup: aPkg,
            filterNotificationTitleGroup: -1,
            filterDate: null,
            filterNotificationByMessage: "",
        })

        window.scrollTo({ behavior: 'smooth', top: 180 })
    }


    handleSearchInputChange = (e) => {

        const newFilterNotificationByMessage = e.target.value

        let notificationsFilteredAux = this.state.notifications.filter(function (not) {
            return not.message.toLowerCase().includes(newFilterNotificationByMessage.toLowerCase())
        })

        this.setState({
            filterNotificationByMessage: newFilterNotificationByMessage,
            notificationsFiltered: notificationsFilteredAux,
            filterNotificationPkgGroup: -1,
            filterNotificationTitleGroup: -1,
            filterDate: null,
        })

    }




    handleOrderChange = () => {

        this.setState({
            notificationsFiltered: this.state.notificationsFiltered.reverse(),
            notificationsOrderRecentFirst: !this.state.notificationsOrderRecentFirst
        })
    }



    handleShowAllClick = () => {

        let notificationsAux = this.state.notifications//vuelvo a todos
        sortArrayByRecentFirst(notificationsAux, this.state.notificationsOrderRecentFirst)

        this.setState({
            notificationsFiltered: notificationsAux,
            filterNotificationPkgGroup: -1,
            filterNotificationTitleGroup: -1,
            filterDate: null,
            filterNotificationByMessage: "",
        })
    }



    getNotificationColor = (index) => {
        let colors = ["#007AAE", "#00A2B6", "#00C6A2", "#6082C1"]
        if (!this.currentColor) this.currentColor = colors[0]

        let selectedColor = this.currentColor
        let array = this.state.notificationsFiltered

        if (array.length > 0) {

            let prevNot = array[0]
            let currentNot = array[index]
            if (index > 0) prevNot = array[index - 1]

            if (prevNot.title != currentNot.title) {

                selectedColor = colors[getRandomNumber(1, colors.length - 1)]
                while (selectedColor == this.currentColor)//para que no coincidan nunca con el anterior
                    selectedColor = colors[getRandomNumber(1, colors.length - 1)]

                this.currentColor = selectedColor
            }
        }
        return selectedColor
    }





    render() {
        const { t } = this.props; //función de traducción

        if (!this.state.notifications) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t("Notifications.title")}</h3>
                        <div className="container  text-center mt-4">
                            <Loader type="Rings" color="#fff" height={250} width={250} > </Loader>{t("Notifications.loading")}
                        </div>
                    </div>
                </div>
            )


        } else {


            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t("Notifications.title")}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title="notifications" limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ notifications: [], notificationsFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                    <ShowNoItemsYet size={this.state.notifications.length} itemName="Notifications" />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark blueShadow rounded-lg p-2">

                        <div>
                            <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.notifications} />
                            <SelectNotificationPkgGroup notificationPkgGroup={this.state.notificationPkgGroup} handleOnChange={this.handleNotificationPkgGroupChange} value={this.state.filterNotificationPkgGroup} featureGranted={this.state.featureGranted} />
                            <SelectNotificationTitleGroup notificationTitleGroup={this.state.notificationTitleGroup} handleOnChange={this.handleNotificationTitleGroupChange} value={this.state.filterNotificationTitleGroup} featureGranted={this.state.featureGranted} />
                            <InputSearch value={this.state.filterNotificationByMessage} onChange={this.handleSearchInputChange} placeholder="Search keyword" />
                        </div>

                        <ButtonShowAll size1={this.state.notifications.length} size2={this.state.notificationsFiltered.length} handleShowAllClick={this.handleShowAllClick} />

                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.notificationsFiltered.length} filterName="date" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterNotificationByMessage} filterResultSize={this.state.notificationsFiltered.length} filterName="keyword" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterNotificationPkgGroup} filterResultSize={this.state.notificationsFiltered.length} filterName="App" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterNotificationTitleGroup} filterResultSize={this.state.notificationsFiltered.length} filterName="Title" showAll={this.handleShowAllClick} />

                    {this.state.notificationsFiltered.length > 10 &&
                        <IA_Component prompt={`${t("IA_Prompts.Notifications")} ${JSON.stringify(this.state.notificationsFiltered.slice(0, 500))}.`}
                            featureGranted={this.state.featureGranted} />
                    }


                    <div className="row">

                        <div className="col-12 mb-3">
                            {/* NOTIFICATIONS-------- */}


                            <ButtonOrderFirst length={this.state.notificationsFiltered.length} order={this.state.notificationsOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                            <ul className="list-group list-group-flush" >



                                {this.state.notificationsFiltered.slice(0, this.state.renderSize).map((not, index) => {
                                    return (
                                        <div key={index} >
                                            <GetCurrentDateFromArray array={this.state.notificationsFiltered} index={index} itemName="notifications" />
                                            <ShowNotification
                                                not={not}
                                                filter={this.state.filterNotificationByMessage}
                                                color={this.getNotificationColor(index)}
                                                featureGranted={this.state.featureGranted}
                                                deleteObject={this.deleteObject}
                                                filterByTitle={this.filterByTitle}
                                                filterByPkg={this.filterByPkg}
                                            />
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.notificationsFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.notificationsFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>


                    </div>

                </div>
            )

        }
    }
}
Notifications.contextType = MiContexto
Notifications = withTranslation()(Notifications);
export default withRouter(Notifications);





function SelectNotificationPkgGroup(props) {
    const { t } = useTranslation() //función de traducción

    const { notificationPkgGroup, value, featureGranted } = props
    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-bell fa-1x "></i></span>
            </div>
            <select value={value} className="form-control miSelect" id="selectPkgGroup" onChange={props.handleOnChange} >
                <option value={-1} >{t("Notifications.AllAps")}</option>
                {notificationPkgGroup.map((group, index) => {
                    return (<option key={index} value={group.paquete}>{group.paquete}  ({group.cantNots}) </option>)
                })}
            </select>
        </div>)
}

function SelectNotificationTitleGroup(props) {
    const { t } = useTranslation() //función de traducción

    const { notificationTitleGroup, value, featureGranted } = props
    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-comment fa-1x "></i></span>
            </div>
            <select value={value} className="form-control miSelect" id="selectTitleGroup" onChange={props.handleOnChange} >
                <option value={-1} >{t("Notifications.allTitles")}</option>
                {notificationTitleGroup.map((group, index) => {
                    let title = group.title
                    if (!title) title = "untitled"
                    return (<option key={index} value={title}>{title}  ({group.cantNots}) </option>)
                })}
            </select>
        </div>)
}






function ShowNotification(props) {
    const { t } = useTranslation() //función de traducción

    let { not, filter, color, featureGranted } = props

    //------
    let key = not.key
    let title = not.title
    let message = not.message
    let paquete = not.paquete
    let fecha = not.fecha
    let hora = not.hora
    let unRead = not.unRead
    //-------
    if (title == "untitled") title = ""


    let BounceLeft = false
    let listCss = { backgroundColor: color };



    if (featureGranted) {
        //SE MUESTRA
        return (
            <Bounce left={BounceLeft}>
                <li className={"list-group-item py-1"} style={listCss} >
                    <div className="row pt-1">
                        <div className="col-12">
                            {unRead && <span className="badge badge-danger">{t("New")}</span>}
                            <h6 className="text-dark mb-1" onClick={() => props.filterByTitle(not.title)}><i className={"fa fa-" + getIcoByApp(paquete) + " fa-1x mr-1"} /><u>{title}</u> </h6>
                            <h6 className="text-white" style={{ wordWrap: 'break-word' }}>{highlightFilter(message, filter)} </h6>
                            <h6 className="text-dark font-italic mb-0" onClick={() => props.filterByPkg(not.paquete)} ><u>{paquete}</u></h6>
                        </div>
                        <div className="col-12">
                            <div className="text-white small d-flex justify-content-end">
                                <ShowItemDate fecha={fecha} hora={hora} />
                                <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
                            </div>
                        </div>
                    </div>
                </li>
            </Bounce>
        )
    } else {


        //DEMO******
        return (
            <Bounce left={BounceLeft}>
                <li className={"list-group-item py-1"} style={listCss} >
                    <div className="row pt-1">
                        <div className="col-12">
                            {unRead && <span className="badge badge-danger">{t("New")}</span>}
                            <h6 className="text-dark mb-1" ><i className={"fa fa-" + getIcoByApp(paquete) + " fa-1x mr-1"} /><u>{title}</u> </h6>
                            <BuySubscriptionTag />
                            <h6 className="text-dark font-italic mb-0"><u>{paquete}</u></h6>
                        </div>
                        <div className="col-12">
                            <div className="text-white small d-flex justify-content-end">
                                <ShowItemDate fecha={fecha} hora={hora} />
                                <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
                            </div>
                        </div>
                    </div>
                </li>
            </Bounce>
        )
    }


}